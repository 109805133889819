.expert-quote-background{
    border: 2px solid #858585;
    padding: 15px 0 0 15px !important;
}

.expert-quote-container{
    border: 2px solid #858585;
    background-color: white;
    padding:30px !important;
    margin-bottom: -15px;
    margin-right: -15px !important;
   display:inline-block;

}

@media only screen and (max-width: 768px){
    .expert-quote-background{
        margin: 0 1rem;
        width: 90%;
    }
    .expert-quote-container{
        margin-bottom: -10px;
        margin-right: -15px;
    }
  }

.latest-read-title p{
    font-size: 14px;
    color: #E74010;
    font-weight: 600;
    margin-bottom: 15px;
}

.expert-quote-updated p{
    font-size: 30px;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 15px;
    letter-spacing: -.05rem;
}

.quote-source p{
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 15px;
}

/* ------ add space after search filter reset icon --------*/
.icon-button{
    margin-right: 5px;
    margin-top: -2px;
}