.heading {
  font-weight: $font-regular;
  letter-spacing: $x-small-space;
  text-align: left;
  line-height: 1.75em;
  color: $nearly-black;

  &--light {
    color: $white;
  }

  &--left {
    text-align: left;

    &:before {
      display: none;
    }

    & span {
      background: none;
      padding: 0;
    }
  }

  &--small {
    font-size: 1rem;
    margin: 0 0 10px;

    @include mq($from: tablet) {
      font-size: 1.125rem;
    }

    &.page-content p {
      margin: 0;
    }
  }

  &--medium {
    font-size: 2rem;
    margin: 0 0 10px;

    @include mq($from: tablet) {
      font-size: 2.5rem;
    }
  }

  &--large {
    font-size: 2rem;
    letter-spacing: $x-small-space;
    margin: 0 auto;
    width: 100%;
    max-width: 1180px;

    @include mq($from: tablet) {
      font-size: 2.75rem;
    }
  }

  &--section {
      margin: 0 0 32px 0;
      border-bottom: 2px solid $primary-color;
      width: fit-content;
      padding-bottom: 2px;
      line-height: 1;
      font-weight: $font-semi-bold;
      h2 {
        line-height: 2rem;
        margin-bottom: 0;
        padding-bottom: 0;
      }

    @include mq($from: desktop) {
      font-size: 2.05rem !important;
      letter-spacing: 0;
    }
  }
}

.page-content.heading-section h2 {
  display: table;
  white-space: nowrap;
  font-size: 1.125rem;
  margin-bottom: 40px;
  overflow: hidden;
  text-transform: none;
  font-family: $secondary-font;
  font-weight: $font-regular;
  letter-spacing: $x-small-space;

}

.modal {
  & p {
    color: $nearly-black;
    text-align: left !important;
    font-size: 1rem;
    margin-bottom: 1rem !important;

    &:last-of-type {
      margin: 0;
    }
  }

  .button {
    border-radius: 25px;
    min-width: 200px !important;
    position: absolute;
    right: 30px;
    bottom: 5px;
    &:hover {
      background-color: $red;
    }
  }
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-centre {
  text-align: center;
}

.light-text {
  color: $white;

  & a {
    color: $white;

    &:hover {
      border-color: transparent;
    }
  }
}

.colour-bg {
  background-color: $primary-color;

  &--blue {
    background-color: $blue;
  }
}

.bg-padding {
  &--large {
    padding: 50px 10px;

    @include mq($from: wide) {
      padding: 50px 0;
    }
  }
}


h2 {
  font-size: 1.9rem !important;
  letter-spacing: $x-small-space;
  font-weight: 600;
  line-height: 2.3rem;
  @include mq($from: desktop) {
    font-size: 2.05rem !important;
    line-height: 2.6rem;
    margin: 1rem 0 .5rem 0;
  }
}

h3 {
  font-size: 1.3rem !important;
  letter-spacing: $small-space;
  font-weight: $font-semi-bold;
  line-height: 1.6rem;
  padding-bottom: 5px;
  border-bottom: 2px solid $primary-color;
  width: fit-content;


  @include mq($from: desktop) {
    font-size: 1.375rem !important;
    line-height: 1.8rem;
    margin: 1.3rem 0 1rem 0;
  }
}

h5 {
  font-weight: large
}

p {
  font-size: $body-font-size;
  line-height: 1.5;
  letter-spacing: $small-space;
  margin: 1rem 0;
  font-weight: 400;

  & strong{
    font-weight: 600;
  }
  & a{
    color: $link-color;
    word-wrap:break-word;
    :hover{
      color: $secondary-color;
    }
  }
}

span {
  word-break: break-word;
}

.datasummary-intro{
  font-size: 1.35rem !important;
  line-height: 1.4em !important;
  margin: 0;
  font-weight: 400 !important;
  letter-spacing: $small-space;
  @include mq($from: desktop){
    font-size: 1.8rem !important;
  }
}

ul {
    list-style:disc;
    margin-left: 1.5rem;
    list-style-position: outside;
    font-size: 1rem;

  }

ol {
    list-style:decimal;
    margin-left: 1.5rem;
    list-style-position: outside;
    font-size: 1rem;
}

li {
  margin-bottom: .7rem ;
  letter-spacing: $small-space;
  font-size: $body-font-size;
}

b, strong {
  font-weight: $font-semi-bold;
}

.video__wrapper {
  width: auto;
  div {
    width: 370px !important;
    height: 200px !important;

    @include mq($from: desktop){
      width: 640px;
      height: 360px;
    }
  }
}
