.grid {


  &--site-layout {
    min-height: 100%;
    grid-template-rows: 60px 1fr auto;
    @include mq($from: desktop) {
      grid-template-rows: 171px 1fr auto;
    }
  }

  &--three {
    @include mq($from: phablet) {
      grid-gap: 20px;
    }
    @include mq($from: desktop) {
      grid-gap: 40px;
    }
  }

  &--four {

    @include mq($from: phablet) {
      grid-gap: 20px;
      //grid-template-columns: repeat(4,minmax(0,1fr));
      //grid-template-columns: repeat(2,minmax(0,1fr));
    }
    @include mq($from: desktop) {
      grid-gap: 30px;
      //grid-template-columns: repeat(4, 1fr) !important;
    }
  }

  &--two-thirds-left {
    grid-template-columns: 1fr;
    @include mq($from: tablet) {
      grid-gap: 40px;
      grid-template-columns: 66.6% auto;
    }
  }

  &--three-quarters-left {
    grid-template-columns: 1fr;
    @include mq($from: desktop) {
      grid-gap: 40px;
      grid-template-columns: auto minmax(280px, 300px);
    }
  }

  &--three-quarters-right {
    grid-template-columns: 1fr;
    @include mq($from: tablet) {
      grid-gap: 40px;
      grid-template-columns: minmax(280px, 1fr) auto;
    }
    @include mq($from: desktop) {
      grid-gap: 40px;
      grid-template-columns: minmax(280px, 480px) auto;
    }
  }
  &--content-page {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    @include mq($from: tablet) {
      grid-template-columns: 48px auto;
      grid-template-rows: auto;
      grid-gap: 40px;
      & .sidebar {
        grid-column: 1 / 3;
      }
    }
    @include mq($from: desktop) {
      grid-template-columns: 48px minmax(auto, 620px) auto;
      grid-gap: 40px;
      & .sidebar {
        grid-column: 3;
      }
    }
  }
}
