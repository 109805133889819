body {
  font-family: $primary-font ;
  padding: 60px 0 0;
  text-align: left;

  @include mq($from: desktop) {
    padding-top: 0;
  }
}

img {
  /*width:auto;*/
  max-width: 100%;
  height: auto;
}
