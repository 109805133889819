.video {
  &--expert-profile {
    height: 100%;

    &>div:first-child {
      height: 100%;

      &.video__wrapper {
        padding-bottom: 100%;

        &.yt-facade {
          height: 100%;
        }
      }
    }
  }

  &--parallax {
    position: relative;
    width: 100%;
    height: 70vh;
    pointer-events: none;

    @include mq($from: tablet) {
      height: 100vh;
    }

    .video-react-control-bar-auto-hide {
      display: none !important;
    }

    .video__wrapper {
      overflow: hidden;
      position: absolute;
      clip: rect(0, auto, auto, 0);
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;

      iframe,
      video {
        position: fixed !important;
        top: 100px;
        object-fit: cover !important;
        display: block;
        max-height: unset;
        height: 100%;
        width: 100%;
      }
    }

    .video-react-loading-spinner {
      visibility: hidden;
      display: none;
    }

    .video-react-controls-enabled {
      padding-top: 0 !important;
    }

    video {
      display: block;
      max-width: 100%;
      height: 70vh;

      @include mq($from: tablet) {
        height: 100vh;
      }
    }

    .video-react {
      background-color: transparent;

      .video-react-big-play-button,
      .video-react-big-play-button-left,
      .big-play-button-hide {
        display: none;
        top: -500px
      }

    }
  }

  &--title {
    padding: 20px !important;
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 99999;
  }

  &--intro {
    padding: 20px !important;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 99999;
  }
}

.with-overlay {

  .video-react-playing,
  .video-react-has-started,
  .with-overlay {
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(1px);
      z-index: 1;
    }
  }

}


.initial-letter {
  &::first-letter {
    -webkit-initial-letter: 3;
    initial-letter: 3;
    margin-right: 15px;
    font-weight: 800;
  }
}

.white-background {
  background-color: white;

  @include mq($from: tablet) {
    flex-direction: row !important;
  }

  @include mq($from: desktop) {
    margin: 0;
  }

  &--blur {
    background-color: rgba(255, 255, 255, 0.7) !important;
    backdrop-filter: blur(5px);
  }

  h2 {
    text-transform: uppercase;
    margin: 0 0 15px 0;
    font-size: 2rem;
  }
}

.align--center {

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    text-align: center;
  }
}