.site-container {
  @apply flex-1 flex-col;

  align-items: stretch;
  height: 100%;
  @include mq($from: desktop){
  padding:0;
  }

  & main {
    flex-grow: 1;
    width: 100%;
    margin: 0;

    @include mq($from: tablet) {
      margin: 0 auto;
    }
  }

  & header,
  footer {
    flex-shrink: 0;
  }
}

#strapline {
  text-align: right;
  margin-bottom: 5px;
}

.logo-container {
  align-items: center;
  justify-content: center;
}

.quote-author {
  font-size: larger;
  font-weight: bold;
}

.row {
  &--menu {
    @apply space-x-10 flex z-50;
    display: flex;
    align-items: center;
    margin-left: -10px !important;
    flex-shrink: 0;

    & > div {
      left: 0 !important;
    }
    & > ul {
      margin-left: 0px !important;
    }
  }

  .heading--large {
    text-align: left;
    font-family: $primary-font;
    text-transform: none;
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: $x-small-space;
    line-height: 125%;
    margin-bottom: 1rem;
    @include mq($from: desktop) {
      font-size: 2.75rem;
      line-height: 115%;
      margin-bottom: .75rem;
    }

  }

  h1.heading--large {
    margin-bottom: 20px;
  }

  &--section {
    .image__wrapper {
      margin: 40px;
      img {
        align-items: center;
      }
    }

    button {
      border-radius: 0;
      background-color: $secondary-color;
    }

    a {
      z-index: 10;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 0;
    }

    .page-content {
      padding: 20px;
    }
  }

  &--page-content {
    @apply mt-40 lg:mt-0;
  }

  &--content {
    position: relative;

    button {
      z-index: 10;
      position: absolute;
      bottom: -40px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--category {
    background-color: whitesmoke;
    box-shadow: 0px -60px 0px 0px whitesmoke;
    width: 60%;

    .heading {
      &--section {
        color: $secondary-color;
        font-size: 2.3rem;
        text-align: center;

        &::after {
          content: none !important;
        }

        &::before {
          content: none !important;
        }
      }
    }
  }

  .list__item {
    border: 0;
    background: none;
    text-align: center;
    padding: 0;
    position: relative;
    border-bottom: 1px solid $grey-medium;

    .card{
      &--pattern-bg{
        margin-left: 20px;
      }

      &__content{
        /*margin-top: 15px;*/
      }
      &__details {
        display: flex;
        letter-spacing: 0;
        flex-direction: column;
        .date {
          width: max-content;
        }
        .venue {
         font-weight: 400 !important;
         width: 100%;
         line-height: 1;
        }
        span {
          margin: 3px 0 0 0;
        }
      }
    }
  }

  .card__content--portrait {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    mix-blend-mode: darken;
    margin-top: 23px;
  }

  .list__item--compact {
    padding-bottom: 23px;
  }

  .card__image--landscape {
    background-color: transparent;
  }

  .card__content-wrapper--landscape {
    max-width:90%;
    @include mq($from: desktop) {
    max-width: 100%;
    }
  }

  .card__details--landscape {
    span:first-child {
      font-weight: bold;
      color: $link-color;
    }

    span:nth-child(2) {
      color: #000;
      font-size: 16px;
    }
  }

  p em {
    font-weight: bold;
    font-size: 12px;
    @apply text-center;
  }

  .social--follow {
    display: flex;
    align-items: center;
    @include mq($from: desktop) {
    margin-bottom: 40px;
    }

    a {
      width: 40px;
      height: 40px;
      margin-right: 10px;

      i {
        font-size: 1.2rem;
      }
    }
  }

  .code {
    #mc_embed_signup_scroll {
      @apply flex align-text-top justify-center;

      input {
        height: 50px;
        border-radius: 0;
      }

      .clear {
        border-radius: 0;
      }

      .button {
        background-color: $red !important;
        height: 40px;
        width:100px !important;
        @include mq($from: desktop) {
          width:100%;
        }

        &:hover {
          background: darken($red, 10%);
        }
      }
    }
  }
}

.bg-dark-gold {
  background: linear-gradient($white, #eee0d1);
}

.bg-light-gold {
  background-color: #e6d4b7;
}

.bg--light-grey {
  background-color: $light-gray !important;
  padding-top: 30px !important;
  .button--link, a.button--link {
    background-color:$light-gray !important;
  }
}

.bg {
  &--dark {
    background-color: $nearly-black;
  }

  &--grey {
    background-color: $grey;
  }

  &--light {
    background-color: $grey-light;
  }

  &--dark-green {
    background-color: transparent;
  }

  &--light-green {
    background-color: #b8dedc;
  }

  &--white-text {
    @apply text-white bg-partners md:text-lg;

    text-align: center !important;
    p {
      font-size: 1.125rem;
      line-height: 1.75em;
      margin: 0 0 30px;
      @include mq($from: tablet) {
        font-size: 2rem;
      }
    }
  }
}

.image__details,
.video__details {
  margin-top: 0px;
  padding-left: 0px;

}

.card--overlay {
  @apply bg-yellow-600 w-full h-full align-middle absolute inset-0 opacity-0 z-50 group-hover:text-white group-hover:opacity-100 grid content-center p-6;
}

.card__content-wrapper {
  @apply z-10;
}

.logo {
  width: 200px;
  @apply invisible h-0 lg:visible lg:h-auto;
}

.sidebar {
  @apply bg-gray-50 pl-2 pr-2;
}

.paragraph-relaxed {
  & p {
    @apply leading-relaxed;
    margin: 10px 0;
  }
}

#es-header .es-follow-btn {
  background-color: $red !important;
}

.social--follow {
  align-items: flex-start !important;
}

.sticky--header {
  position: sticky !important;
  top: 0px !important;
  z-index: 9999 !important;
  border-top: none;
  min-height: 85px;
  background-color: white !important;
  border-top: 5px $nearly-black solid;

  @include mq($from: desktop) {
    min-height: 156px;
    height: $header-height;
  }

}

.card--category {
  & .badge {
    position: relative !important;
    min-width: 30%;
    /* padding: 5px; */
    top: 0px;
    right: 0px;
    align-self: self-start !important;
    opacity: 1;
    color: #e74010 !important;
    background: transparent !important;
    text-align: left;
    padding: 0 !important;
    text-transform: none !important;
    font-size: 16px;
  }
}

.card__content {
  &--overlay {
    position: absolute;
    top: 44% !important;
    height: 52%;
    width: 40%;
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    font-size: large;
    left: 70px;

    & > div {
      @include mq($from: mobile) {
        margin-right: 0px !important;
        margin-left: 0px !important;
      }
    }

    &__title {
      white-space: normal !important;
      position: relative;
      font-size: 1.5rem;
      font-weight: 900;
      top: 0px;
      max-width: 340px;


      @include mq($from: mobile) {
        font-size: 10px !important;
        font-weight: 900;
        top: -20px;
      }

      @include mq($from: tablet) {
        font-size: 1rem !important;
        font-weight: 900;
        top: 0px;
      }

      @include mq($from: desktop) {
        font-size: 1.5rem !important;
        font-weight: 900;
        top: 0px;
      }
    }
  }
}


.card__content--portrait {
  display: block;
}

.menu--search-input__desktop {
  margin-left: 0px !important;
}

.spec-block {
  &__title {
    font-weight: bold;
  }
}

.btn--subscribe {
  @include mq($from: mobile) {
    max-width: 200px;
  }
  @include mq($from: tablet) {
    margin-left: 10px;
  }
  @include mq($from: desktop) {
    margin-left: 40px;
  }
}

@media only screen and (max-width: 768px){
  .btn--subscribe {
    margin-top: 30px;
  }
}

.main {
  margin-top: 0px !important;
}

.az_list_filter {
  display: flex;
  justify-content: space-between;
  font-size: .875rem;
  color:#ccc ;
  font-weight: 400;
  a {
    color: #000;
  }
  a:hover{
    color: #e74010;
  }
  .selected{
    color: #006d77;
    text-decoration: underline;
    font-weight: 600;
  }
}

.text-left {
  text-align: left;
}


.backtotop {
  @include mq($from: mobile) {
    bottom: 50px !important;
  }
}

.filter__refine--mobile-open .search {
  background: $primary-color !important;
}

.row--wide {
  @include mq($from: mobile) {
    padding-left: 5px;
  }

  @include mq($from: desktop) {
    padding-left: 5px;
  }
}

.social-follow {
  margin-left: auto;
  margin-right: auto;
}

.aw0 {
  @include mq($from: mobile) {
    visibility: hidden;
  }
}

.row--page-content {
  @include mq($from: mobile) {
    margin-top: 100px !important;
  }

  @include mq($from: tablet) {
    margin-top: 150px !important;
  }
}

@media only screen and (max-width: 768px){
   .az_list_filter_option{
     margin-right: 1.7rem;
   }
   .az_list_filter {
    overflow-y: auto;
   }

}
