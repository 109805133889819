.filter {
  &--search-refine {
    background-color: $grey-dark;
    border: none;
    border-radius: 0;
    margin-top: 115px;
    height: 100vh;
    position: fixed;
    width: 100%;
    @include mq($from: tablet) {
      border-radius: 40px;
      padding: 10px 15px;
      margin-top: 0;
      position: relative !important;
      height: auto;
    }

    & .heading--section {
      font-size: .75rem !important;
      color: $gray !important;
      width: max-content !important;
      margin: 0;
      padding: 0;
      font-weight: $font-light;
      letter-spacing: $med-space;
      text-transform: uppercase;
      display: inline-block;
      white-space: normal;
      line-height: 1.25em;
      text-align: left;
      padding-bottom: 0;
      border-bottom: none;
      background: none;
      margin-bottom: -5px;
      border-radius: 0;
      padding-left: 10px;

      &:after,
      &:before {
        display: none;
      }
    }

    & p {
      text-align: left;
      font-size: 0.875rem;
      margin: 0 0 15px;
    }
    & .button {
      margin-top: 10px;
      display: none;
    }

    .search__wrapper {
      margin-top: 30px ;
      @include mq($from: tablet) {
        margin-top: 0 ;
        margin-left: 0 !important;
      }
     }
  }


  .form-field {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 2px 0 0 -4px;
    width:fit-content;

    &--input {
      min-width: 340px;
      @include mq($from: tablet) {
        min-width: auto;
      }
    }

    &--select {
      margin: -2px 0 0 0;
      padding: 0;
      margin-right: 0;
      max-width: 360px;
      @include mq($from: desktop) {
      max-width: 200px;
      }
      select,
      select[disabled] {
        background-color:$grey-dark;
        color: $white;
        border: 0;
        cursor: default;
        padding-left: 0;
        font-size: .875rem;
        font-weight:$font-regular;
        padding: 0 30px 0 0;
        border: none !important;
        background-image: url(/static/img/drop-down-white.svg);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding-left: 10px;
        &:focus {
          //min-width: 300px;
          width: auto;
        }
        @include mq($from: desktop) {
          font-size: 1rem;
        }

        option {
          //min-width: 300px;
        }
      }

    }


  }

  &__wrapper {
    display: flex;
    flex-direction: column !important;

    @include mq($from: tablet) {
      flex-direction: row !important;
      justify-content: space-between;
    }

    &--search-refine {
      @include mq($from: tablet) {
        flex-direction: column;
      }
    }

    &--search-button {
      @include mq($from: tablet) {
        display: none;
      }
    }
  }

  &__section {
    margin-top: 1.5rem;
    @include mq($from: tablet) {
      margin-top: 0;
    }
    &--checkboxes {
      margin-bottom: 20px;
    }

    &--search {
      margin-bottom: 0;
    }

    select {
      min-width: 280px;
      @include mq($from: tablet) {
      min-width: 180px;
      }
      @include mq($from: desktop) {
        min-width: 200px;
        }
    }
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey;
    height: 50px;
    margin-bottom: 10px;
    @include mq($from: tablet) {
      flex: 1 1 730px;
      margin-right: 40px;
      margin-bottom: 0;
    }
  }

  &__tags {
    display: none;
    padding: 10px;
    position: absolute;
    background: rgba($white, 0.9);
    z-index: 10;
  }

  &__button {
    &.open {
      & i {
        transform: rotate(180deg);
      }
    }
  }

  &__reset {
    display: flex;
    float: right;
    margin: .6rem .2rem ;

    .icon-button{
      color: $white;
      margin: auto 0;
      font-weight: 300;
      & svg{
        width: 36px;
        height: 36px;
        border-radius: 25px;
        padding: 10px;
        border: 1px solid #FFf;

      }

    }
    .icon-button~div{
      display: none;
    }

    & p {
      margin: 0;
      font-size: 0.875rem;
    }
  }

  &__form--mobile {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    background-color: $white;
    border-radius: 5px;
    overflow-x: hidden;
    z-index: 999;
    display: block !important;
    @include mq($from: tablet) {
      all: initial;
    }
  }

  &__form--collapsible {
    @include mq($until: tablet) {
      display: none;
    }

    & .filter__reset {
      @include mq($until: tablet) {
        display: none;
      }
    }
  }

  &__header {
    left: 0;
    right: 0;
    z-index: 1000;
    position: fixed;
    color: $white;
    font-family: "Apercu", sans-serif;
    font-weight: 700;

    &--mobile {
      top: 90px !important;
      display: flex;
      place-content: space-between;
      align-items: center;
      padding: 0 15px;
      height: 44px;
      background-color: $grey-darkest;
      z-index: 999999;
      p {
        margin: 15px;
      }

      @include mq($from: tablet) {
        display: none;
      }

      & i {
        font-size: 1.5em;
      }
    }
  }

  &__refine {
    @extend .filter__header;

    &--mobile {

      &-close {
        bottom: 0;
        font-size: 1rem;
        line-height: 40px;
        position: fixed;
        width: 100%;
        height: 50px;
        text-align: center;
        background-color: $white;
        @include mq($from: tablet) {
          display: none;
        }

        div {
          margin: 4px auto;
          width: 360px;
          background-color: $primary-color;
          height: 42px;
          border-radius: 25px;
          &:hover {
            background-color: $primary-color;
          }
        }
      }
      &-open {
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
        width: 100vw;
        background-color: #FFf;
        @include mq($from: tablet) {
          display: none;
        }

        & .reset {
          color: $primary-color;
          line-height: 1.875rem;
          padding: 4px 10px 2px 10px;
          height: 42px;
          border: 2px solid $primary-color;
          border-radius: 25px;
        }

        & .search {
          width: 160px;
          height: 42px;
          padding: 4px 25px 2px 25px;
          color: $white;
          font-weight: 500;
          border: 0;
          align-items: center;
          justify-content: center;
          border-radius: 25px;
        }
      }
    }
  }

  .icon-button{

    &--search{
      right: 35px;
      @include mq($from: desktop) {
        right: 10px;
       margin: 0 0 0 -1.8rem ;
      }
    }
  }

}

.pair {
  display: flex;

  & .form-field {
    & + .form-field {
      margin-left: 5px;
    }
  }
}

.range-slider {
  width: 100%;
  height: 25px;
  background-color: $grey-lightest;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  border: 0;
  border-radius: 12px;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $primary-color;
    cursor: pointer;
    border: 0;
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $primary-color;
    cursor: pointer;
    border: 0;
  }

  &__wrapper {
    text-align: left;

    & p {
      margin: 0;
      font-size: 0.875rem;
    }

    & + .range-slider__wrapper {
      margin-top: 15px;
    }
  }
}

.see-more {
  font-size: 0.75rem;
  text-align: left;
  font-weight: $font-semi-bold;

  & i {
    margin-right: 5px;
  }
}

.sort-by .form-field {
  width: 100%;
  @include mq($from: desktop) {
  width: 30%;
  }
}

.column--two-thirds {
  .filter .search input {
    min-width:auto;
  }
  .search__wrapper {
    min-width: auto;
  }
}
