.heading--large {
  font-family: $secondary-font;
  letter-spacing: $small-space;
  margin: 0 0 10px;
  width: 100%;
  max-width: 1180px;
  font-weight: 400;
  line-height: 1.1em;
  font-size: 1.75rem;
  @include mq($from: phablet) {
    font-size: 2rem;
    margin: 0 0 10px;
  }
  @include mq($from: tablet) {
    font-size: 2.5rem;
  }
  @include mq($from: desktop) {
    font-size: 2.75rem;
  }
}

.details {
  text-align: left;
  color: $navbar-item-secondary;
  font-size: 2.2rem;
  font-weight: 300;
  text-transform: none;
  font-family: $primary-font;
  margin-bottom: 5px;

  & span {
    display: inline-block;
    padding: 3px 0;
    font-size: 2rem;
    font-weight: 400;

    & + span {
      margin-right: 0;
      margin-left: 5px;
    }
  }
}

.stats {
  width: auto;
  &__value {
    font-size: 1.125rem;
    line-height: 1.8rem;
    word-break: break-word;
  }

  &__heading{
    margin-top: 25px;
    margin-bottom: 0px;
    @include mq($from: tablet) {
    line-height: 1 !important;
    }
    font-size: .938rem;
    .name {
      letter-spacing: 0;
    }
    .title a, .company a {
      letter-spacing: 0;
      font-size: 1.3rem;
    }

    span{
      color: $link-color;
      text-transform: capitalize;
      line-height: 1;
      display: inline-block;
      margin-right: 10px;
      &:last-of-type {
        padding: 0;
        border: none;
        margin: 0;
      }
    }
  }

  &__text {
    margin: 20px 0;
    @include mq($from: desktop) {
    margin: 0 0 10px 0;
    }
  }

}
