.chip {
  background-color: lighten($teal, 30%);
  transition: $animate-default;

  &--clickable {
    &:hover {
      background-color: lighten($teal-lightest, 20%);
    }
  }

  &--deletable {
    &:hover {
      background-color: lighten($teal-lightest, 20%);
    }
  }

  &__label {
    padding: 0 17px;
  }

  &__icon {
    color: $teal;
    opacity: .6;

    &--delete {
      transition: $animate-default;

      &:hover {
        opacity: .8;
      }
    }
  }

  &__wrapper {
    margin: 0 -5px;
  }
}
