.image {
  line-height: 0;
  position: relative;
  display: block;

  & img{
    height: auto;
    width: auto;
    object-fit: cover;
    object-position: top;
  }

  &--full-width {
    width: 100%;
  }

  &--top {
    margin: 0px;
    display: inline-block;
  }

  &.avatar {
    width: 36px;
    height: 36px;
  }

  & + div {
    margin-top: 0;
  }

  /*&__details {
    @apply absolute inset-0;
  }*/
}

.image-top {
  margin: 0 0 15px;
  display: inline-block;
}

.image__details,
.video__details {
  flex-direction: column;
  padding-left: 5px;
  margin-top: 15px;
  line-height: 1.25em;

  & .caption {
    color: #fff;
    background-color: $dark-gray;
    height: auto;
    padding: 5px;
    text-align: left;
  }

  & .credits {
    padding: 5px;
    background-color: $white;
    font-size: .875rem;
    text-align: left;
    color: $grey-dark;
  }
}

.caption-overlay {
  & .caption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 20px;
    background-color: rgba($black, 0.5);
    color: rgba($white, 0.9);
    font-size: 1.75rem;
    line-height: 1.25em;
    font-family: $secondary-font;
    font-weight: $font-regular;
    letter-spacing: $small-space;

    @include mq($from: tablet) {
      font-size: 2rem;
      padding: 40px;
    }

    @include mq($from: desktop) {
      font-size: 2.5rem;
      padding: 40px;
    }
  }
}

.image .caption {
  margin-top: 0px !important;
}

.profile img,
.card--profile img {
  max-width: 160px;
  display: initial;
}

.featured--card .image__details {
  position: relative;
  margin-top: 10px;
}

@media (min-width: 48em){
  .card--square .card__image img {
    min-height: auto;
}
}

.card__image--squares img{
  min-width: 120px;
}
