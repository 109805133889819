.pagination{
 display: flex;
 flex-wrap: wrap;
}


.pagination_page-previous, .pagination_page-next{
    margin-left: 1rem;
    border: 1px solid $grey;
    padding: 3px 15px;
    border-radius: 25px;
    color: $primary-color;
    align-items: center;
    display: flex;
    margin: 10px 0 10px 1rem;
    @include mq($from: desktop) {
      margin: 0 0 0 1rem;
    }

    &:hover{
        border: none;
        background-color: $primary-color;
        color: $white;
    }
}

.pagination_page-item{
    border: 1px solid $grey;
    padding: 3px;
    border-radius: 25px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 10px 1rem;
    @include mq($from: desktop) {
      margin: 0 0 0 1rem;
    }

    &:first-child {
      margin:10px 0 10px 0;
      @include mq($from: desktop) {
        margin: 0;
      }
    }
    &:hover{
        border: none;
        background-color: $primary-color;
        color: $white;
    }
    &--current {
        border: 1px solid $primary-color;
        color: $primary-color;
        padding: 3px;
        border-radius: 25px;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin:10px 0 10px 1rem;
        @include mq($from: desktop) {
          margin: 0 0 0 1rem;
        }
    }
}
