/* Shutter In Horizontal */
.hvr-shutter-in-horizontal {
  display: flex;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: lighten($primary-button-color, 10%);
  transition-property: color;
  transition-duration: .3s;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $primary-button-color;
    transform: scaleX(1);
    transform-origin: 50%;
    transition-property: transform;
    transition-duration: .3s;
    transition-timing-function: ease-out;
  }

  &:hover {
    color: $white;

    &:before {
      transform: scaleX(0);
    }
  }

  &:focus {
    color: $white;

    &:before {
      transform: scaleX(0);
    }
  }

  &:active {
    color: $white;

    &:before {
      transform: scaleX(0);
    }
  }
}
/* Shutter Out Horizontal */
.hvr-shutter-out-horizontal {
  display: flex;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: $primary-button-color;
  transition-property: color;
  transition-duration: .3s;
}

.hvr-shutter-out-horizontal:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: lighten($primary-button-color, 10%);
  transform: scaleX(0);
  transform-origin: 50%;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: ease-out;
}

.hvr-shutter-out-horizontal:active,
.hvr-shutter-out-horizontal:focus,
.hvr-shutter-out-horizontal:hover {
  color: $white;
}

.hvr-shutter-out-horizontal:active:before,
.hvr-shutter-out-horizontal:focus:before,
.hvr-shutter-out-horizontal:hover:before {
  transform: scaleX(1);
}

/* Ripple Out */
@keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

.hvr-ripple-out {
  display: flex;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    border: $primary-button-color solid 6px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation-duration: 1s;
  }

  &:active:before {
    animation-name: hvr-ripple-out;
  }

  &:focus:before {
    animation-name: hvr-ripple-out;
  }

  &:hover:before {
    animation-name: hvr-ripple-out;
  }
}
/* Rectangle Out */
.hvr-rectangle-out {
  display: flex;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: $primary-button-color;
  transition-property: color;
  transition-duration: .3s;
}

.hvr-rectangle-out:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: lighten($primary-button-color, 10%);
  transform: scale(0);
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: ease-out;
}

.hvr-rectangle-out:active,
.hvr-rectangle-out:focus,
.hvr-rectangle-out:hover {
  color: $white;
}

.hvr-rectangle-out:active:before,
.hvr-rectangle-out:focus:before,
.hvr-rectangle-out:hover:before {
  transform: scale(1);
}
/* Pop */
@-webkit-keyframes hvr-pop {
  50% {
    transform: scale(1.2);
  }
}
@keyframes hvr-pop {
  50% {
    transform: scale(1.2);
  }
}

.hvr-pop {
  display: flex;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-pop:active,
.hvr-pop:focus,
.hvr-pop:hover {
  animation-name: hvr-pop;
  animation-duration: .3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
/* Push */
@-webkit-keyframes hvr-push {
  50% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1);
  }
}
@keyframes hvr-push {
  50% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1);
  }
}

.hvr-push {
  display: flex;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-push:active,
.hvr-push:focus,
.hvr-push:hover {
  animation-name: hvr-push;
  animation-duration: .3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
