%card-title {
  margin: 0 0 20px;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.025em;
}

.card {
  box-shadow: none;
  background: none;
  max-width: 100%;
  position: relative;

  &--featured{
    .badge{
      border-left: none !important;
      padding-left: 0 !important;
      &:before{
        content: "Featured";
        color: $white;
        background-color: $link-color;
        border-left: none;
        margin-right: .6rem;
        padding: 1px 4px 1px 20px;
        border-radius: 3px;
        background-image: url(/static/img/star-white.svg);
        background-repeat: no-repeat;
        background-position: 4px;
        letter-spacing: $small-space;
        }
    }
  }

  &--sponsored{
    .badge{
      border-left: none !important;
      padding-left: 0 !important;
      &:before{
        content: "Sponsored";
        color: $white;
        background-color: $primary-color;
        border-left: none;
        margin-right: .6rem;
        padding: 1px 4px 1px 20px;
        border-radius: 3px;
        background-image: url(/static/img/star-white.svg);
        background-repeat: no-repeat;
        background-position: 3px;
        font-weight: $font-regular;
        letter-spacing: $small-space;
        }
    }
  }

  &--leaders {
    .badge--label {
      visibility: collapse;
    }

    .card{
      &__image--leaders {
        margin: 0 auto !important;
        img {
          margin: 0 auto !important;
          border-radius: 50%;
          object-fit: cover;
          object-position: top;
          width: 200px;
          height: 200px;
          @include mq($from: tablet) {
            width: 110px;
            height: 110px;
          }
          @include mq($from: desktop) {
            width: 160px;
            height: 160px;
          }
        }
      }

      &__content-wrapper{
        white-space: normal;
      }

      &__content{
        align-items: center !important;
        margin-top: -10px !important;
      }

      &__image {
        align-items: center !important;
      }

      &__details  {
        display: flex;
        flex-direction: column;
        &--details {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: center;
          word-break: break-word;
        }
        &--company {
          text-align: center;
          letter-spacing: 0;
          color: $dark-gray;
          margin: 0 auto !important;
          @include mq($from: desktop) {
          font-weight:600 !important;
          line-height: 1.6rem;
          }
        }


        &--job-title {
          text-align: center;
          margin: 0 auto !important;
        }

      }


    }

  }

  &--company {
    display: flex;
    flex-direction: column !important;
    .badge--label {
      visibility: collapse;
    }

    .card{
      &__image--landscape{
        width: 100% !important;
        flex: 0 1 200px;
      }
      &__image--leaders {
        margin: 0 auto !important;
        img {
          margin: 0 auto !important;
          border-radius: 50%;
          object-fit: cover;
          object-position: top;
          @include mq($from: desktop) {
            width: 160px;
            height: 160px;
          }
        }
      }

      &__image--company {
      background-color: $white;
      border: 2px solid $grey;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100% !important;


      @include mq($from: desktop) {
        height: 200px;
        padding: 40px;
      }

      img {
        border: none;
        max-height: 120px !important;
        align-items: center;
        min-width: 150px;
      }
    }

    &__content-wrapper{
        white-space: normal;
      }

    &__content--company {
        text-align: left;
        margin-top: -10px !important;
        padding-left: 0;
      }

      &__image {
        align-items: center !important;
        @include mq($from: tablet) {
        min-height: 200px;}
        @include mq($from: desktop) {
          min-height: auto;
        }
      }

      &__details  {
        display: flex;
        flex-direction: column;
        &--details {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: center;
          word-break: break-word;
        }
        &--company {
          text-align: center;
          letter-spacing: 0;
          color: $nearly-black;
          text-align: left;
          @include mq($from: desktop) {
          font-weight:600 !important;
          line-height: 1.6rem;
          }
        }
        &--job-title {
          text-align: center;
        }

      }


    }

  }

  &--unfeatured-company {
    /*border: 2px solid $grey;*/
    background-image: url(/static/img/Line.png);
    padding: 10px;
    min-height: 80px;
    .card__content{
      margin-top: 0 !important;
    }
  }

  & img{
    border: 1px solid $gray;
  }
  &--no-image{
    margin-top: 25px !important;
    margin-left: -20px;
  }

  &--pattern-bg{
    background-image: url("/static/img/Line.png");
    background-repeat: repeat;
    background-position: top;
    -moz-border-radius-bottomright: 10px;
    -webkit-border-bottom-right-radius: 20px;


    .card__content-wrapper{
      padding: 0 10px;
    }
    .card__content{
      min-height: 120px;
      &--portrait{
        margin-top: 15px;
      }
      &--landscape {
        padding-left: 0;
        margin-top: 15px;
      }
    }

    .card__image--landscape{
      object-fit: cover;
      margin-left: -30px;
      flex: none;

    }

    .card__image--portrait {
      width: 85%;
      position:absolute;
      right: 0;
      bottom: 0;
      @include mq($from: tablet) {
        bottom: 0;
      }
      @include mq($from: desktop) {
        bottom: 40px;
      }


      & img{

        margin-bottom: -150px;
      }
    }


  }


  & .badge {
    /*background: $grey;*/
    position: relative;
    color: rgba($black, 0.6);
    padding: 0 0 0 5px;
    top: 0px;
    right: 0px;
    opacity: 1;
    font-size: 0.7rem;
    letter-spacing: 2px;
    font-weight: 400;
    border-left: 1px solid rgba($primary-color, 0.8);
    max-width: max-content;
    line-height: 1;
    margin-bottom: 6px;
  }

  &--light-text {
    background: none;
    border: 0;
    padding: 0;

    &:hover {
      box-shadow: none;
      transform: none;
    }
  }

  &--landscape {
    flex-direction: row;
    height: auto;
    max-width: 100%;

    &--pattern-bg{
      margin-left: 30px;
    }

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &--portrait-to-landscape {
    flex-direction: row;
    height: auto;
    margin: 10px 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    @include mq($from: mobileBig) {
      flex-direction: column;

      &:first-of-type {
        margin-top: auto;
      }

      &:last-of-type {
        margin-bottom: auto;
      }
    }
  }

  @include mq($from: desktop) {
    &--square {
      width: 100% !important;
      min-width: 350px;
      .card__image img {
        min-height: 350px;
      }
    }
  }



  @include mq($from: phablet) {
    &--square {
      width: 100% !important;
      min-width: 200px;
      .card__image img {
        min-height: 200px;
      }
    }
  }

  @include mq($from: tablet) {
    &--square {
      width: 100% !important;
      min-width: 250px;
      .card__image img {
        min-height: 250px;
      }
    }
  }




    &--category {
      & .card__title {
        position: relative;
        font-weight: bold;
        font-size: .9rem !important;
        font-family: $secondary-font;
        width: 100%;
        &:before {
          position: absolute;
          background-color: $horizontal-line-separator;
          content: "";
          height: 1px;
          width: 70%;
          bottom: -7px;
        }
      }
      .card__details {
        font-size: .8rem;
        font-weight: unset;
        width: 100%;
      }
      & .badge {
        position: relative;
        color: black;
        text-align: left;
        background-color: unset;
        width: 100%;
        font-size: 1rem;
        text-transform: none;
        &:hover {
          background-color: unset;
          color: black;
        }
      }
    }

  &--related {
    flex-direction: row;
    height: auto;
    margin: 0 0 10px;

    &:last-of-type {
      margin: 0 0 30px;
    }

    @include mq($from: mobileBig) {
      flex-direction: column;
      margin: 0 0 30px;
    }
  }

  &--hero {
    border: 1px solid $grey-light;
    box-shadow: none;
    align-self: start;
    flex-direction: row;
    height: auto;
    background: $white;
    overflow: visible;
    max-height: 100%;

    @include mq($from: phablet) {
      box-shadow: $shadow-hard;
    }

    &:hover {
      & img {
        opacity: 1;
        transform: none;
      }
    }
  }

  &--overlay {
    background: none;
    box-shadow: none;
    padding: 0;
    position: relative;
    overflow: hidden;
    background-color: $black;
    margin-bottom: auto;

    &:hover {
      & img {
        opacity: 1;
        transform: scale(1.05);
      }

      & .card__content {
        background-color: rgba($primary-color, 0.8);

        & .card__details {
          margin-bottom: 10px;
          opacity: 1;
          transform: scaleY(1);
        }
      }

      & .card__title {
        margin-bottom: 5px;
        text-align: left;

        & a {
          border-bottom-color: transparent;
        }
      }

      & .badge {
        background: none;
        color: $white;
      }
    }

    & .badge {
      padding: 5px;
      border-radius: 3px;
      background-color: rgba($black, 0.6);
    }
  }

  &__image {
    position: relative;
    &--circle-image{
      flex: auto;
      & img {
        height: 60px;
        width: 60px;
        object-fit: cover;
        border-radius: 60px;
        @include mq($from: desktop) {
        height: 110px;
        width: 110px;
        }
      }
    }
    &--video {
      & a:after {
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url("/static/img/play.png");
        opacity: 0.8;
        transition: $animate-default;
      }

      &:hover {
        & a:after {
          opacity: 1;
        }
      }
    }

    &--landscape {
      flex: auto;
      background-color: $grey-light;
      width: 120px;

      @include mq($from: mobileBig) {
        flex: 0 1 200px;
        //max-width: 57px;
      }

      @include mq($from: desktop) {
        flex: 0 1 240px;
        max-width: 106px;
      }

      & .icon-button {
        width: 37px;
        height: 37px;

        & i {
          color: $primary-color;
          font-size: 2.2rem;
        }
      }
    }

    &--portrait-to-landscape {
      flex: 1 0 40%;
      background-color: $grey-light;

      @include mq($from: mobileBig) {
        flex: 0 0 100%;
      }

      @include mq($from: desktop) {
        flex: 0 0 100%;
      }
    }

    &--related {
      flex: 0 0 120px;
      background-color: $grey-light;

      @include mq($from: mobileBig) {
        flex: 0 0 auto;
      }

      @include mq($from: desktop) {
        flex: 0 0 auto;
      }
    }

    &--hero {
      display: none;
      flex: 1 0 40%;
      background-color: $grey-light;

      @include mq($from: phablet) {
        display: block;
        flex: 1 1 50%;
      }

      @include mq($from: tablet) {
        flex: 1 1 200px;
      }

      @include mq($from: desktop) {
        flex: 1 1 300px;
      }

      & img {
        transition: none;
        opacity: 1;
      }
    }

    &--overlay {
      background-color: $card-overlay-bg;

      & img {
        transition: $animate-default;
        opacity: 0.5;
        object-fit: cover;
      }
    }
  }

  &__details {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25em;
    color: black;

    .date {
      width: 100%;
      font-size: 1rem !important;
      color: $grey-dark !important;
      margin-top: 0;
    }

    & span {
      margin: 5px 0 0 0 !important;
      display: inline-block;
      width: auto;
      line-height: 150%;
      font-weight: $font-regular !important;
      font-size: 1.125rem;
      padding: 5px 0 0 0;
      &:only-child {
        margin: 0;
      }
    }

    &--overlay {
      font-size: 0.875rem;
      font-weight: $font-light;
      color: $white;
      width: 100%;
      max-width: 400px;
      transition: $animate-default;
      font-family: $primary-font;
      opacity: 0;
      transform: scaleY(0.001);
      margin-bottom: -20px;
    }

    &--hero {
      margin: 15px 0 0;
      line-height: 1.5em;
    }
  }

  &__content {
    padding: 0;

    & p {
      font-size: 0.875rem;

      @include mq($from: phablet) {
        font-size: 1rem;
      }
    }

    &--landscape {
      display: flex;
      flex-direction: column;
      justify-content: left;
      padding-left: 15px;

      /*@include mq($from: phablet) {
        padding-left: 30px;
      }*/
    }

    &--portrait-to-landscape {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 5%;

      @include mq($from: mobileBig) {
        justify-content: flex-start;
        padding-left: 0;
      }
    }

    &--related {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 5%;

      @include mq($from: mobileBig) {
        justify-content: flex-start;
        padding-left: 0;
      }
    }

    &--hero {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 1 50%;
      text-align: center;
      padding: 20px;
      position: relative;
      left: auto;
      right: auto;
      bottom: auto;

      @include mq($from: phablet) {
        flex: 0 1 50%;
      }

      @include mq($from: tablet) {
        padding: 10px 30px;
      }

      & h2 {
        font-size: 1rem;
        font-style: italic;
        font-weight: $font-light;
        letter-spacing: $x-small-space;
        margin: 0;
        line-height: 1.5em;
      }

      & p {
        margin: 15px 0;
      }

      & h2 + p {
        margin-top: 30px;

        &:last-of-type {
          margin-top: 30px;
        }
      }

      @include mq($from: mobileBig) {
        & h2 {
          font-size: 1.125rem;
        }
      }

      @include mq($from: phablet) {
        text-align: left;
      }

      @include mq($from: desktop) {
        & h2 {
          font-size: 1.5rem;
        }
      }
    }

    &--light-text {
      & p {
        color: $grey-light;
      }
    }

    &--overlay {
      color: $white;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      padding: 20px;
      transition: $animate-default;

      @include mq($from: desktop) {
        padding: 30px;
      }

      @include mq($from: desktop) {
        padding: 30px;
      }
    }
  }

  &__title {
    font-size: 1.3rem !important;
    font-weight: $font-semi-bold !important;
    margin: 0;
    line-height: 1.3em;
    text-align: left;
    letter-spacing: $small-space;

    @include mq($from: phablet) {
      font-size: 1.35rem !important;
    }

    @include mq($from: desktop) {
      font-size: 1.375rem !important;
    }

    & a {
      padding-bottom: 3px;
      transition: $animate-default;
      border: none !important;

      &:hover {
        border: none;
        text-decoration: none;
        color: $primary-color;
      }
    }

    &--landscape {
      margin: 0;
      font-size: 0.875rem;

      @include mq($from: tablet) {
        font-size: 0.875rem;
      }

      @include mq($from: desktop) {
        font-size: 1rem;
      }

      @include mq($from: wide) {
        font-size: 1.125rem;
      }
    }

    &--portrait-to-landscape {
      margin: 0;
      font-size: 0.875rem;

      @include mq($from: mobileBig) {
        margin-top: 10px;
        font-size: 1.125rem;
      }

      @include mq($from: tablet) {
        font-size: 1.125rem;
        margin: 10px 0 20px;
      }

      @include mq($from: desktop) {
        font-size: 1.25rem;
      }
    }

    &--related {
      margin: 0;
      font-size: 0.875rem;

      @include mq($from: mobileBig) {
        margin-top: 0;
        font-size: 1rem;
      }

      @include mq($from: tablet) {
        font-size: 1rem;
        margin: 0;
      }

      @include mq($from: desktop) {
        font-size: 1rem;
      }
    }

    &--light-text {
      color: $grey-light;

      & a {
        border-color: $grey-light;
        color: $grey-light;

        &:hover {
          color: $white;
          text-decoration: none;
        }
      }
    }

    &--overlay {
      margin: 5px 0 20px;
      font-weight: $font-regular;
      letter-spacing: $small-space;
      transition: $animate-default;

      & a {
        color: $white;
        padding: 5px;
        border-color: $primary-color;
      }
    }

    &--hero {
      font-size: 1.5rem;
      margin: 10px 0;
      font-family: $secondary-font;
      font-weight: $font-semi-bold;

      @include mq($from: mobileBig) {
        font-size: 2rem;
      }

      @include mq($from: tablet) {
        font-size: 2.5rem;
        margin: 15px 0 20px;
      }

      @include mq($from: desktop) {
        font-size: 2.75rem;
      }

      & a {
        color: $black;

        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  &__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($secondary-color, 0.8);
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
    display: flex;
    flex-direction: column;
  }
  &--background {
      &__light {
        background-color: $grey-light;
        padding: 10px;
        .card {
          &__content {
          margin-top: 5px !important;
        }
       }
      }

      &__border {
        border: 1px solid $grey;
        padding: 10px;
        .card {
          &__content {
          margin-top: 5px !important;
        }
       }
      }
    }
  }

.card--leaders.card--sponsored {
  .badge {
    border-left: none !important;
    padding-left: 0 !important;
    position: absolute;
    top: 5px;
    left: 65%;

    &:before{
      content: "";
      color: $white;
      background-color: $red;
      border-left: none;
      margin-right: .6rem;
      padding: 3px 5px 3px 20px;
      border-radius: 25px;
      background-image: url(/static/img/star-white.svg);
      background-repeat: no-repeat;
      background-position: 5px 5px;
      visibility:visible;
      }
  }

  .card__content {
    margin-top: 10px !important;
  }

}

.card--company.card--sponsored {
  .badge {
    border-left: none !important;
    padding-left: 0 !important;
    position: absolute;
    top: 15px;
    left: 12px;

    &:before{
      content: "Premier";
      background-color: $red;
      border-left: none;
      margin-right: .6rem;
      padding: 3px 5px 3px 20px;
      background-image: url(/static/img/star-white.svg);
      background-repeat: no-repeat;
      background-position: 5px 5px;
      visibility:visible;
      font-size: 1rem;
      }
  }

  .card__content {
    margin-top: 10px !important;
  }

}


.card__content-wrapper--pattern-bg.card__content-wrapper--portrait{
  min-height: 320px;
}

.card__image + .card__content {
  /*If there is an image before content*/
  padding-top: 7px;

  &--related {
    padding-top: 0;
  }

  &--hero {
    padding-top: 20px;

    @include mq($from: tablet) {
      padding-top: 10px;
    }
  }
}

.card--pattern-bg.card--landscape{
      margin-left: 30px;
      padding-bottom: 15px;
      @include mq($from: mobile) {
        max-width: 90%;
      }
      @include mq($from: tablet) {
        max-width: 95%;
      }

      @include mq($from: desktop) {
        max-width: 100%;
      }

}

.card--pattern-bg.card--portrait {
  padding: 0 10px 10px 10px;
  margin-bottom: 180px;
  @include mq($from: tablet) {
    margin-bottom: 0;
  }
  @include mq($from: desktop) {
    padding: 0 10px 30px 10px;
    margin-bottom: 0;
  }

  .card__content-wrapper {
      @include mq($from: desktop) {
      padding: 0 10px 60px 10px;
      }
  }
}

.card__image--landscape + .card__content {
  /*If there is an image before content*/
  padding-top: 0;
}

.featured--card {
  margin-bottom: 40px !important;
  position: relative;

  .column {
    &--one-quarter {
      width: 100%;
      position: relative;
      z-index: 1;
      background-color: $featured-card-bg;
      padding: 35px;

      @include mq($from: tablet) {
        width: 33%;
        position: absolute;
        padding: 60px 40px;
        margin: 25px;
      }
    }
  }

  .page-content {
    width: 100% !important;
    padding: 20px 10px 0 10px;
    font-family: $primary-font;

    & h2 {
      font-size: 2rem;
      text-align: left;
    }
  }

  & .button {
    color: $primary-button-color;
    font-family: $secondary-font !important;
    padding-left: 10px;

    &:hover {
      color: darken($primary-button-color, 10%);
    }
  }

  &.left {
    .image {
      margin-left: 0;

      @include mq($from: tablet) {
        margin-left: 33%;
      }
    }

    & .column {
      &--one-quarter {
        max-width: 100%;
        @include mq($from: tablet) {
          top: 0;
          left: 0;
        }

        @include mq($from: desktop) {
          top: 10%;
          left: 0;
        }
      }
    }
  }

  &.right {
    & .column {
      &--one-quarter {
        @include mq($from: tablet) {
          top: 10%;
          right: 5%;
        }

        @include mq($from: desktop) {
          top: 25%;
          right: 9%;
        }
      }
    }
  }
}

.featured--winners {
  & .page-content {
    width: 100% !important;
    text-align: left;
    padding: 5px 10px;

    & h2 {
      font-size: 2rem;
      font-family: $primary-font;
    }

    & a {
      color: $black;

      &:hover {
        color: $primary-color;
      }
    }

    & p {
      text-align: left !important;
      font-size: 1.5rem !important;
    }
  }
}


.card__title--editors-picks a {
		 white-space: normal;
}


.grid--four .card__image {
  display: flex !important;
}
