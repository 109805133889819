.social {
  &--follow {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__list-item {
    &--follow {
      flex: 0 0 24px;
    }

    &--share {
      flex: 0 0 48px;
    }

    & a {
      background-color: $grey-light;

      &:hover {
        opacity: 1;
        text-decoration: none;
      }

      &.facebook {
        background-color: black;

        &:hover {
          background-color: black;
        }
      }

      &.twitter {
        background-color: $nearly-black;

        &:hover {
          background-color: black;
        }
      }

      &.pinterest {
        background-color: black;

        &:hover {
          background-color: black;
        }
      }

      &.linkedin {
        background-color: $nearly-black;

        &:hover {
          background-color: black;
        }
      }

      &.youtube {
        background-color: black;

        &:hover {
          background-color: black;
        }
      }

      &.instagram {
        background-color: black;

        &:hover {
          background-color: black;
        }
      }
    }

    &--follow {
      margin: 0 4px 0 0;

      &:last-of-type {
        margin: 0;
      }

      & a {
        width: 24px;
        height: 24px;

        & i {
          font-size: .75rem;
          line-height: normal;
        }
      }
    }

    &--share {
      & a {
        width: 48px;
        height: 48px;

        & i {
          font-size: 1.5rem;
        }
      }
    }
  }

  &--vertical {
    & .social__list-item {
      margin: 5px 0;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__wrapper {
    &--content-page {
      display: none;
      @include mq($from: tablet) {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 768px){
 .social__list {
  margin: 1rem auto 0 auto;
 }
}
