/* ==========================================================================
   Colours
   ========================================================================== */

   $nearly-black: #10080d;
   $gray: #c8c0c5;
   $light-gray: #efedee;
   $dark-gray: #7F5F5E;
   $primary-color: #ab0000;
   $secondary-color: #ab0000;

   $button-cta-color: #ab0000;
   $link-color: #ab0000;


   /* ==========================================================================
      Fonts
      ========================================================================== */

   $primary-font:  Poppins, sans-serif;
   $secondary-font: 'Lato', sans-serif;
   $x-small-space: -.015rem;
   $small-space: 0;
   $med-space: 0.075rem;
   $space: 0.25rem;


/* ==========================================================================
    images files
    ========================================================================== */
    $linkButtonIcon: url(./custom/project/img/arrow-right-red.svg);
    $starIcon: url(./custom/project/img/star-red.svg);
    $desktopLogo: url(./custom/project/img/NM-II-logo.png);
    $mobileLogo: url(./custom/project/img/NM-II-logo.png);

    $header-height: 176px;
