.content-group-tabs {
  width: 100%;

  &__buttons-wrapper {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    z-index: 99;
    @include mq($from: desktop) {
    top: $header-height;
    }
  }

  &__button {
    margin-right: 5px;
    text-align: start;
    flex: 1 1 25%;
    font-weight: $font-semi-bold;
    padding-bottom: 6px;
    color: $grey-medium;
    border: 0;
    border-bottom: 3px solid $grey-medium;

    &--is-active {
      margin-right: 5px;
      text-align: start;
      flex: 1 1 25%;
      font-weight: $font-semi-bold;
      padding-bottom: 6px;
      color: $black;
      border: 0;
      border-bottom: 3px solid $black;
    }
  }
}

.content-group-sidepanel {
  width: 100%;
  flex-direction: column;
  display: flex;

  @include mq($from: desktop) {
    width: 100%;
    flex-direction: row;
    display: flex;
  }

  &__buttons-wrapper {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    margin-left: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    height: fit-content;
    background-color: $white;
    z-index: 99;

    @include mq($from: desktop) {
      width: 40%;
      display: block;
      overflow: hidden;
      margin-right: 2rem;
      position: -webkit-sticky;
      position: sticky;
      top:   $header-height+132px;
      height: fit-content;
    }
  }

  &__button {
    width: 100%;
    margin-top: 5px;
    display: list-item;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 8px;
    text-align: start;
    font-weight: $font-regular;
    padding-bottom: 6px;
    color: $black;
    border: 0;
    min-width: 150px;
    background-color: $light-gray;
    border-radius: 10px;
    border: 2px solid $secondary-color;

    @include mq($from: desktop) {
      border-left: 3px solid $secondary-color;
      border-top: none;
      border-right: none;
      border-bottom: none;
      background-color: white;
      min-width: auto;
      border-radius: 0;
    }

    &--is-active {
      width: 100%;
      margin-top: 5px;
      display: list-item;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 8px;
      text-align: start;
      font-weight: $font-semi-bold;
      padding-bottom: 6px;
      color: $black;
      border: 0;
      background-color: $secondary-color;
      border-left: 3px solid $primary-color;
      min-width: 150px;
      border-radius: 10px;

      @include mq($from: desktop) {
        min-width: auto;
        border-radius: 0;
      }
    }
  }

  &__content-section {
    width: 100%;

    .carousel {
      max-width: 820px;

      &__slide {
        width: 100%;
        margin-right: 10px;
        height: 194px;

        &:last-child {
          margin-right: 0px;
        }

        @include mq($from: tablet) {
          width: 33%;
          height: 141px;
        }

        @include mq($from: desktop) {
          width: 33%;
          height: 149px;
        }
      }

      & img {
        height: auto;
        width: 100%;

        @include mq($from: tablet) {
          position: inherit;
          left: auto;
          top: auto;
          transform: none;
          min-width: 100px;
        }

        @include mq($from: desktop) {
          min-width: 100px;
        }
      }

    }
  }
}
