.newsletter {
  width: 100%;
  padding: 30px;
  background-color: $primary-color;
  color: $white;
  position: relative;
  overflow: hidden;
  max-width: 360px;
  margin: 0 auto;
  align-self: start;
  /*needed if in grid*/
  @include mq($from: phablet) {
    max-width: 100%;
    padding: 40px;
    margin: 0;
  }
  @include mq($from: desktop) {
    display: flex;
    max-width: 360px;
    padding: 30px;
  }

  &--no-image {
    height: auto;
  }

  &--wide {
    @include mq($from: phablet) {
      max-width: 1180px;
      display: flex;
      padding: 0;
    }
    @include mq($from: desktop) {
      max-width: 1180px;
      height: auto;
    }
  }

  &--secondary-color {
    background-color: $secondary-color;
  }

  & .form-field {
    max-width: 100%;

    & input {
      border: 0;
    }
  }

  & a {
    color: $white;

    &:hover {
      text-decoration: underline;
    }
  }

  &__content {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    text-align: left;
    @include mq($from: phablet) {
      flex-direction: row;
    }
    @include mq($from: desktop) {
      flex-direction: column;
    }

    &--wide {
      @include mq($from: phablet) {
        flex: 1 1 auto;
        padding: 30px;
      }
      @include mq($from: desktop) {
        flex-direction: row;
        padding: 30px 30px 30px 10px;
      }
    }
  }

  &__text {
    @include mq($from: phablet) {
      padding: 0 20px 50px 0;
      flex: 0 1 50%;
    }
    @include mq($from: desktop) {
      padding: 0;
    }

    & h2 {
      font-size: 1.5rem;
      font-family: $secondary-font;
      margin: 0 0 5px;
      letter-spacing: $small-space;
      @include mq($from: phablet) {
        font-size: 1.75rem;
      }
    }

    & p {
      font-size: 1rem;
      letter-spacing: $x-small-space;
      margin: 0;
      max-width: 300px;
    }

    &--wide {
      @include mq($from: phablet) {
        align-self: center;
      }
      @include mq($from: desktop) {
        padding: 0 20px 50px 0;
        flex: 0 1 50%;
      }
    }
  }

  &__form {
    margin: 20px 0;
    @include mq($from: phablet) {
      margin: 0;
      flex: 0 1 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @include mq($from: desktop) {
      margin: 30px 0 20px;
    }

    &--wide {
      @include mq($from: desktop) {
        margin: 0;
        flex: 0 1 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      @include mq($from: desktop) {
        margin: 0;
      }
    }
  }

  &__message {
    width: 100%;
    max-width: 220px;
    margin: 0 auto;
    text-align: center;
    @include mq($from: phablet) {
      font-size: .75rem;
      position: absolute;
      left: 0;
      bottom: 0;
      text-align: left;
      padding-right: 20px;
      width: 50%;
      max-width: 50%;
    }
    @include mq($from: desktop) {
      width: 100%;
      max-width: 200px;
      margin: 0 auto;
      position: relative;
      left: auto;
      bottom: auto;
      text-align: center;
      padding-right: 0;
    }

    &--wide {
      @include mq($from: phablet) {
        text-align: left;
        font-size: .75rem;
        position: absolute;
        left: 30px;
        bottom: 40px;
        padding-right: 20px;
        width: 43%;
        max-width: 43%;
      }
      @include mq($from: desktop) {
        left: 10px;
        bottom: 50px;
        padding-right: 20px;
      }
    }
  }

  &__image {
    opacity: .2;
    position: absolute;
    top: -28%;
    left: 10px;
    right: 10px;
    z-index: 1;
    @include mq($from: phablet) {
      top: -22%;
      left: 10px;
      right: auto;
    }
    @include mq($from: desktop) {
      right: 10px;
    }

    & + .newsletter__content .newsletter__form {
      margin: 20px 0;
      @include mq($from: phablet) {
        margin: 0;
      }
      @include mq($from: desktop) {
        margin: 30px 0 20px;
      }
    }

    &--wide {
      @include mq($from: phablet) {
        margin: 0;
        top: -40%;
      }
      @include mq($from: desktop) {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        padding: 10px;
        flex: 0 1 18%;
      }

      & + .newsletter__content .newsletter__form {
        margin: 15% 0 20px;
        @include mq($from: phablet) {
          margin: 0;
        }
        @include mq($from: desktop) {
          margin: 0;
        }
      }
    }
  }
}

.sidebar .newsletter {
  @include mq($from: desktop) {
    height: 470px;
  }
}

.newsletter-cta,
.newsletter-cta-wide {
  background-repeat: no-repeat;
  background-color: $primary-color;
  background-size: 96%;
  background-position: center -80px;
  padding: 20px 30px;
  color: $white;
  flex: 1 1 100%;

  & .page-content h2 {
    font-family: $secondary-font;
    font-weight: $font-semi-bold;
    font-size: 1.75rem;
    margin: 0 0 10px;
  }

  & .page-content p {
    font-size: 1.25rem;
    line-height: 1.25em;
    margin: 0 0 15px;
    font-weight: $font-semi-bold;
    letter-spacing: $x-small-space;
  }

  & .page-content h6 {
    font-size: .75rem;
    text-align: center;
    font-weight: $font-semi-bold;
    margin: 0;
  }

  & .page-content a {
    color: $white;
    border-bottom: 1px solid rgba($white, .5);
    padding-bottom: 1px;
    transition: $animate-default;

    &:hover {
      border-color: transparent;
      text-decoration: none;
    }
  }
}

.newsletter-cta-wide {
  background-size: contain;
  background-position: 10px 10px;
  @include mq($from: desktop) {
    background-size: 200px;
  }

  & .page-content h6 {
    text-align: left;
  }

  & .column:first-of-type {
    @include mq($from: desktop) {
      margin-top: 30px;
      margin-bottom: auto;
    }
  }
}
