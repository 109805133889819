.card {
  & .badge {
    font-weight: bold;
    padding: 0;
    line-height: 1em;
    white-space: normal;
    border-radius: 0;
    border: none;
    text-transform: uppercase;
    color: $grey-medium;
    letter-spacing: $small-space;
    margin: 0;
    cursor: default;
    font-family: $primary-font;
    font-size: 0.75rem;
    @apply min-w-min;

    &:hover {
      background: none;
    }

    & a {
      color: $grey-medium;
      transition: $animate-default;
      cursor: pointer;

      &:hover {
        color: $grey-dark;
      }
    }
  }

  &--landscape .badge {
    margin-top: 0;
  }

  &--portrait-to-landscape .badge {
    margin-top: 0;
    @include mq($from: mobileBig) {
      margin: 10px 0;
    }
  }

  &--light-text .badge {
    color: $grey-light;

    & a {
      color: $grey-light;

      &:hover {
        color: $white;
      }
    }
  }

  &--large .badge {
    margin-top: 10px;
    @include mq($from: phablet) {
      margin-top: 0;
    }
  }

  &--related .badge {
    margin: 0;
    @include mq($from: mobileBig) {
      margin-top: 5px;
    }
  }
}

.card a.badge {
  color: $red;
  transition: $animate-default;
  cursor: pointer;

  &:hover {
    color: $link-color;
    text-decoration: none;
  }
}

