.banner {
  &--wallpaper {
    position: absolute;
    overflow: hidden;
    height: $banner-wallpaper-height;

    .ad-slot {
      width: 100%;
      position: relative;
      left: 0;
      right: 0;

      @include mq($from: desktop) {
        float: none;
        margin: 0;
        display: block;
        position: fixed;
        right: -9999px;
        left: -9999px;
        margin: auto;
        width: $banner-wallpaper-width;
        height: $banner-wallpaper-height;
        top: $header-height;

        ::before {
          content: "";
          position: absolute;
          top: 0;
          max-width: $banner-waller-paper-before-width;
          width: 100%;
          height: 100%;
          left: $banner-waller-paper-before-left;
          pointer-events: auto; // blocks clicks to wallpaper
        }
      }
    }
  }
}

.banner+p {
  margin-top: 25px;
}

p+.banner {
  margin-top: 28px;
}
.adunitContainer {
  margin: 20px 0;
  @include mq($from: desktop) {
    margin: 0;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 0px) {
  .banner--wallpaper .ad-slot {
    display: none;
  }
}
