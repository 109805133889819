figure {
  margin: 0;
}

#mc_embed_signup {
  background: none !important;
  color: $white;

  & form {
    padding: 0 0 10px !important;
  }

  & input {
    border: 0 !important;
  }

  & button {
    @apply border-0;
    background-color: $red;
    color: white;
  }

  & .indicates-required {
    font-size: 0.875rem !important;
    text-align: left !important;
    letter-spacing: $x-small-space;
    font-family: $primary-font;
  }

  & .mc-field-group {
    width: 100% !important;
    padding: 0;
    margin: 0 0 10px;

    & label {
      color: $white;
    }

    & input {
      border: 0;
      border-radius: 0;
    }
  }

  & .button {
    background-color: $newsletter-bg !important;
    font-size: 0.875rem !important;
    text-transform: uppercase;
    letter-spacing: $small-space !important;
    height: 50px !important;
    width: 100% !important;
    padding: 5px 20px !important;
    transition: background-color 0.5s ease;
    font-weight: $font-bold !important;
    max-width: 100%;

    &:hover {
      background-color: darken($red, 10%) !important;
    }
  }
}
