.filter {
  &__header--mobile {
    z-index: 1001;
  }

  &--search-refine {
    margin-top: 20px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    @include mq($from: tablet) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey;
    height: 50px;
    margin-bottom: 10px;
    @include mq($from: tablet) {
      flex: 1 1 730px;
      margin-right: 40px;
      margin-bottom: 0;
    }
  }

  &__tags {
    display: none;
    padding: 10px;
    position: absolute;
    background: rgba($white, 0.9);
    z-index: 10;
  }

  &__button {
    &.open {
      & i {
        transform: rotate(180deg);
      }
    }
  }

  .form-field{
   &--select{
     max-width: 200px;
   }
  }
}

