.page-content {
  & + .page-content {
    margin-top: 0;
  }

  & .form {
    margin: 0 0 30px;
  }

  &--text-centred {
    @apply pl-10 pr-10;
    text-align: center;

    h3 {
      font-family: brandon-grotesque, sans-serif;
      font-weight: 400;
      font-style: italic;
      font-size: 2rem;
    }

    & p {
      text-align: center;
    }
  }

  &.standfirst p {
    font-size: 1rem;
    line-height: 1.5em;
    font-family: $secondary-font;
    font-weight: $font-semi-bold;
    text-align: left;

    @include mq($from: tablet) {
      font-size: 1.125rem;
      font-weight: $font-light;
    }

    @include mq($from: desktop) {
      font-size: 1.5rem;
    }
  }

  h1 {
    font-family: $secondary-font;
    letter-spacing: $small-space;
    margin: 0 0 10px;
    width: 100%;
    max-width: 1180px;
    font-weight: 400;
    line-height: 1.1em;

    @include mq($from: tablet) {
      font-size:2.75rem;
    }
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: 1.5rem;
    font-weight: $font-light;
    margin: 0 0 10px;
    letter-spacing: $x-small-space;
    line-height: 1.25em;
  }

  h2:first-child {
    font-weight: 600 !important;
  }

  h3 {
    font-weight: $font-semi-bold;
  }

  h4 {
    font-weight: $font-semi-bold;
    font-size: 1rem;
  }

  h5 {
    font-weight: $font-semi-bold;
    font-size: 0.875rem;
  }

  h6 {
    font-weight: $font-regular;
    font-size: 0.75rem;
    text-align: center;
  }

  p {
    font-size: 1rem;
    line-height: 1.5em;
    margin: 0 0 20px;
    font-weight: $font-regular;

    @include mq($from: tablet) {
      font-size: 1.3rem;
      line-height: 1.75em;
      margin: 0 0 30px;
    }
  }

  p a {
    @include link-basic;
    padding-bottom: 0;
    border-bottom: 1px solid $red;
  }

  ol,
  ul {
    list-style: disc;
    padding-left: 40px;
    font-size: 1rem;
    line-height: 1.5em;

    @include mq($from: tablet) {
      font-size: 1.125rem;
      line-height: 1.75em;
    }
  }

  blockquote {
    font-family: $secondary-font;
    font-size: 1.125rem;
    line-height: 1.25em;
    letter-spacing: $x-small-space;
    font-style: italic;
    padding: 20px 0 0 20px;
    margin-bottom: 20px;
    position: relative;
    z-index: 10;
    text-align: left;

    @include mq($from: tablet) {
      font-size: 1.5rem;
      padding: 30px 0 0 30px;
      margin-bottom: 30px;
    }

    @include mq($from: desktop) {
      font-size: 1.5rem;
      padding: 40px 0 0 40px;
      margin-bottom: 40px;
    }

    quotes: none;

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }

  & img {
    margin: 0;
  }

  a {
    color: $black;
    border-bottom: $primary-color;

    &:hover {
      color: $primary-color;
    }
  }

  hr {
    margin: 20px 0;
    padding: 0;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: $grey-dark;
  }

  code {
    padding: 0;
    background: none;
  }
}

.content-details {
  & p {
    margin: 0;
    font-size: 0.875rem;
    color: $grey-medium;
  }
}

blockquote {
  width: 100%;
  padding: 20px;
  margin: 23px 10px 23px 0;
  background-color:  $light-gray;
  text-transform:capitalize;
  border-left: 2px solid $primary-color;
  font-weight: $font-semi-bold;
  letter-spacing: $small-space;
  color: $nearly-black;
  border-top: none;
  font-size: 1rem;

  &::before {
    content: "";
    font-size: 0;
  }
}
