.search {
  align-items: center;
  border: 0;
  max-width: max-content;
  @include mq($from: desktop) {
    border: 0;
    border-radius: 0;
  }

  &--toggle {
    z-index: 2;
    border: 0;
    height: 32px;
    display: flex;
    justify-content: flex-end;
    line-height: 0;
    margin: 0;
    @include mq($from: desktop) {
      border: 0;
    }
  }

  & input {
    height: 46px;
    border: 0;
    background-color: $white;
    border-radius: 25px;
    min-width: 12.5rem;
    @include mq($from: tablet) {
      max-width: 8rem;
    }
    @include mq($from: desktop) {
      height: 48px;
      border-radius: 25px !important;
      min-width: 18rem !important;
    }
  }

  &__wrapper {
    background: none;
    min-width: 15rem;

    &--toggle-display-enter-done {
      & .search--toggle {}
    }

    &--filter {
      width: auto;
      flex: 1 1 auto;
      background-color: $blue-grey-50;
    }
  }

  &__icon {
    opacity: .6;
    background: $primary-color;
    color: white;
    padding: 10px;
    border-radius: 40px;
    opacity: 1;
    margin-top: 14px;

    &:hover {
      opacity: .8;
    }

    &.open {
      background: none;
      padding: 0;
      color: $black;
      border-radius: 0;
      opacity: .8;
      margin-top: 22px;

      &:hover {
        opacity: 1;
      }
    }

    & i {
      font-size: 1.75rem;
    }
  }
}

.search-modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 54px;
  height: 0;
  z-index: 101;
  overflow: hidden;
  display: flex;
  justify-content: center;
  @include mq($from: desktop) {
    top: 71px;
  }

  & a {
    color: $nearly-black;

    &:hover {
      color: $link-color;
    }
  }

  &__content {
    background: $white;
    position: relative;
    height: 100%;
    border-radius: 0 0 10px 10px;
    overflow-y: scroll;
    width: 100%;
    max-width: 1160px;
    @include mq($from: wide) {
      max-width: 1180px;
    }

    &:empty {
      height: 0;
      overflow: hidden;
      padding: 0;
    }
  }
}

.search__wrapper--toggle-display-enter-done .search-modal {
  min-height: 400px;
}

.search-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($black, .4);
  z-index: 100;
}

.search-modal-result {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding: 10px 5px;
  margin: 0 10px;
  width: calc(100% - 20px);
  border-bottom: 1px solid $grey-light;
  font-size: 1rem;
}

.search-modal-miniature {
  max-width: 150px;
  margin-right: 10px;
}

.search-modal-link {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  cursor: pointer;
}
