.breadcrumb {
  display: flex;
  align-content: center;
  width: 100%;
  margin: 12px 0 11px 0;
  color: #bdbdbd;

  &__item {
    margin: 0 6px 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: medium;

    &:last-child{
      &::after {
        content:none;
      }
      & a{
        text-decoration: none;
      }

    }

    & a{
      display:inline-block;
      overflow: hidden;
      max-width: 20rem;
      text-decoration: underline;
      text-overflow: ellipsis;
    }

    &::after {
      content: ">";
      margin-left: 6px;
      vertical-align: top;
    }

  }
}

@media only screen and (max-width: 768px){
  .breadcrumb {
    font-size: 13px;
    margin-bottom: 1.5rem;
    display: list-item;
    align-content: flex-start;
  }
  .breadcrumb__item a {
    max-width: 10rem;
}
.breadcrumb ::after {
  margin-left: 5px;
}
.breadcrumb__item{
  margin-right: 5px;
  display: inline-flex;
}
}
