/*basic link with coloured underline + hover*/
@mixin link-basic {
  color: $nearly-black;
  border-bottom: 1px solid $link-color;
  text-decoration: none;


  &:hover {
    text-decoration: none;
    border-color: transparent;
  }
}
