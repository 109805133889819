.discover {
  width: 100%;
  background: $pink-dark;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    position: relative;
    line-height: 0;
    width: 100%;
    max-width: 1180px;
  }

  &__heading {
    background: $dark-opacity;
    padding: 15px;
    color: $white;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    @include mq($from: tablet) {
      padding: 30px;
      text-align: left;
    }

    & h2 {
      margin: 0;
      font-family: $secondary-font;
      font-weight: $font-regular;
      font-size: 1.5rem;
      line-height: 1.25em;
      @include mq($from: tablet) {
        font-size: 2.625rem;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1180px;
    @include mq($from: tablet) {
      flex-wrap: nowrap;
    }

    & button {
      flex: 0 0 50%;
      @include mq($from: tablet) {
        flex: 0 0 25%;
      }
    }
  }
}
