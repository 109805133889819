.newsletter-signup {
  margin-bottom: 2.5rem !important;
  max-width: 96vw !important;
  @include mq($from: desktop) {
    max-width: 885px !important;
  }

  h2, p {
    text-align: center;
  }
}

#mce-EMAIL {
  background-color: $grey-light !important;
  border-radius: 25px !important;

  &:hover {
    border: 2px solid $link-color !important;
  }
}

#mc_embed_signup {
  margin-top: 20px;
  .clear {
    height: 42px;
    border-radius: 25px;
  }
  input.email {
    font-family: $primary-font !important;
    padding-left: 1rem !important;
    font-size: 1.125rem !important;
    color: $dark-gray !important;
    width: 100% !important;
    @include mq($from: desktop) {
      width: 60%;
    }
  }

}

#mc_embed_signup_scroll {
  .button {
    height: 42px !important;
    border-radius: 25px !important;
    margin-left: -6.5rem !important;
    margin-top: 4px !important;

  }

}

@media only screen and (max-width: 768px) and (min-width: 0px) {
  #mc_embed_signup_scroll .button {
    right:5.5%;
    position: absolute;
  }

  #mc_embed_signup input.email {
    width: 90% !important;
    position: absolute;
  }
}
