.footer {
  border: 0;
  padding: 15px 0 !important;

  &__column {
    flex: 1 1 auto;
    padding-left: 10px;
    @include mq($from: tablet) {
      padding-left: 0;
    }
  }

  &__row {
    border: 0;
    padding: 0;

    &:first-child {
      background: none;
    }
    @include mq($from: tablet) {
      flex-direction: row;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: flex-start;
    padding: 5px 10px;
    text-align: left;
    @include mq($from: wide) {
      padding: 5px 0;
    }

    &--mid {
      flex-direction: column;
      @include mq($from: phablet) {
        flex-direction: row;
        justify-content: space-between;
      }

      & a {
        color: $nearly-black;
        font-weight: $font-semi-bold;
      }
    }

    &--top {
      border-top: 1px solid $grey-light-darkest;
      padding-top: 20px;
    }

    &--base {
      font-size: 0.75rem;
    }
  }

  &__list {
    margin-bottom: 10px;
    @include mq($from: phablet) {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }

  &__list-item {
    margin-right: 10px;
    font-size: 0.875rem;

    &:last-child {
      margin-right: 0;
    }
  }

  & a:hover {
    text-decoration: none;
  }

  &__address {
    line-height: 1.5em;
  }

  .menu--desktop-wrapper ul {
    margin-left: 0;
    font-size: 1rem;
  }
}

.footer--links {
  margin-left: 30px;
}

footer {
  .column {
    padding-left: 20px;
    @include mq($from: tablet) {
      padding-left: 0;
    }
  }
}
