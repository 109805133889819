.expert-hero .heading--large{
    text-align: center !important ;
    padding-top: 30px;
    font-size: 40px !important;
}

.page-expert .card__image img{
    max-height:none;
}
.expert-hero .top-featured--pull-up{
    text-align: center !important ;
    padding: 10px 35px;
}

.page-expert .row--profiles {
    background-image:none !important;
    background-color: #eee0d1 ;
}

.page-expert .card .badge{
    background: #006d77;
}

.page-expert .bg--dark-green {
    background-color: #83c5be;
    padding: 20px;
    margin-bottom: 50px;
}

.page-expert .featured-title-column{
    background-color: #83c5be;
}

.page-expert .featured--card.left .image {
    margin-left: 0;
    @include mq($from: desktop) {
        margin-left: 34%;
    }
}

.page-expert .column--one-quarter{
    margin: 25px 0;
}

.page-expert .column--one-quarter .button--link {
    background-color: #83c5be !important;
}

.page-expert .row-experts{
    text-align: center;

}

/*-----------Responsive: Experts home - featured article image position-----------*/

@media only screen and (max-width: 768px){
    .page-expert .column--one-quarter {
        margin: 0;
    }
    
    .column + .column {
        margin: 0;
    
    }
}