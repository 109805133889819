.cards-carousel {
  @apply relative;

  &--content {
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    overflow: hidden;
  }

  &--arrow {
    &__left {
      transform: rotate(138deg);
      -webkit-transform: rotate(138deg);
      padding: 5px;
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
    }
    
    &__right {
      transform: rotate(315deg);
      -webkit-transform: rotate(315deg);
      padding: 5px;
      margin-left: -6px;
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
    }
  }

  .icon-button {
    width: 4 !important;
  }

  &--left-button-wrapper {
    @apply absolute left-0 h-full flex justify-center items-center z-10;
    height: 100%;
    width: 64px;
  }

  &--right-button-wrapper {
    @apply absolute right-0 h-full flex justify-center items-center z-10;
    height: 100%;
    width: 64px;
  }

  & .card {
    display: inline-block;
    width: 100vw;
    vertical-align: top;

    & .card__image {
      margin: 0px;
    }
  }
}

.cards-carousel .icon-button {
  background-color: white;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}


.add-to-basket{
  .card {
  max-width: 300px;
  padding-right: 30px;
  @include mq($from: mobile){
    max-width: 98%;
    margin-left:10px;
  }
  }

  .card__content-wrapper {
  white-space: normal;
  }
}
