.modal--video{
    z-index: 999999;
}

@media only screen and (max-width: 768px){
    .modal--video {
        max-width: 400px;
        height: 400px;
        top: 30%;
        left: 50%;
        position: relative;
    }

}
