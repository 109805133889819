.header {
  text-align: center;
  display: block;
  left: 0;
  top: 0;

  &__wrapper {
    max-width: 1180px;
    height: 60px;
    margin: 0 auto;
    border-bottom: 1px solid $border-default;
    position: relative;
    @include mq($from: desktop) {
      height: 166px;
    }
  }

  &__row {
    display: flex;
    height: 100%;
    @include mq($from: desktop) {
      padding: 10px 10px 20px;
    }
    @include mq($from: wide) {
      padding: 10px 0 20px;
    }

    &--top {
      position: relative;
    }

    &--base {
      padding: 0;
      display: none;
      @include mq($from: desktop) {
        display: flex;
        position: relative;
        padding: 0 10px;
      }
      @include mq($from: wide) {
        padding: 0;
      }
    }

    &--mobile {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      @include mq($from: desktop) {
        display: none;
      }
    }
  }

  &__column {
    flex-direction: column;
    padding: 0;
    justify-content: center;
    align-items: center;
    flex: 1 1 50px;
    @include mq($from: desktop) {
      justify-content: space-between;
      align-items: flex-end;
      height: 85px;
    }

    &:nth-of-type(1) {
      align-items: flex-start;
      @include mq($from: desktop) {
        display: none;
      }
    }

    &:nth-of-type(2) {
      @include mq($from: desktop) {
        justify-content: center;
        align-items: flex-start;
      }
    }
    @include mq($from: wide) {
      padding: 0;
    }
  }

  &__search {
    transition: flex-basis 0.3s linear;
    flex: 1 1 32px;
    margin-left: auto;
    z-index: 101;
    padding: 5px;
    background: $white;
    @include mq($from: desktop) {
      flex: 1 1 800px;
      padding: 10px 0 10px 10px;
      margin-left: 10px;
    }
  }
}

.logo {
  transition: flex-basis 0.3s linear;
  padding: 0;
  min-width: 150px;
  width: 180px;

  &__mobile {
    padding: 0;
    width: 140px;
    min-width: 140px;
  }
}


