/* ==========================================================================
   Breakpoints
   ========================================================================== */

/* Add breakpoints and tweakpoints here*/

/* To add custom breakpoints directly inside of a component,*/

/* see https://github.com/guardian/sass-mq#adding-custom-breakpoints*/

/* Name your breakpoints in a way that creates a ubiquitous language*/

/* across team members. It will improve communication between*/

/* stakeholders, designers, developers, and testers.*/

$mq-breakpoints: (
  mobile: 320px,
  mobileBig: 480px,
  phablet: 660px,
  tablet: 768px,
  desktop: 1024px,
  wide: 1366px,
);

/* Show the current breakpoint in the top right corner of the viewport:*/

/* see https://github.com/guardian/sass-mq#seeing-the-currently-active-breakpoint*/

/* Uncomment to activate during development*/

/*$mq-show-breakpoints: (mobile, mobileLandscape, phablet, tablet, desktop, wide);*/

/* ==========================================================================
   Colours
   ========================================================================== */

$black: #000000;
$nearly-black: #261213;

$white: #ffffff;

$gray: #E5DDDD;
$light-gray: #f6f0f0;
$dark-gray: #897A7B;



$primary-color: #AB0000;
$button-cta-color: #ccaa6e;

/*teal*/
/*$secondary-color:         #f68787;pink*/
$secondary-color: black;

$tertiary-color: #f2d150;
/*yellow*/

$link-color: #007882;
/*teal*/

$warning: #f44336;
/*red*/
$success: #8bc34a;
/*green light*/

$social-color: black;
/*social grey*/

/*UI colours*/

$teal: #30acb7;
$teal-lightest: #48d5db;
$teal-light: #3cc0c9;
$teal-dark: #2397a4;
$teal-darkest: #178292;

$pink: #f68787;
$pink-lightest: #fac3c3;
$pink-light: #f8a5a5;
$pink-dark: #f36868;
$pink-darkest: #f14a4a;

$yellow: #f2d150;
$yellow-lightest: #f8e878;
$yellow-light: #f5dc64;
$yellow-dark: #eec53b;
$yellow-darkest: #ebb927;
$gtg-yellow: #fcf0d7;

$blue: #5da1d4;
$blue-lightest: #8bd0e9;
$blue-light: #74b8de;
$blue-dark: #4589c9;
$blue-darkest: #2e71be;

$blue-light: #8fcbf3;
$blue-light-lightest: #c7e5f9;
$blue-light-light: #abd8f6;
$blue-light-dark: #72bdef;
$blue-light-darkest: #56b0ec;

$grey-light: #ebebeb;
$grey-lightest: #f5f5f5;
$grey-light: #f0f0f0;
$grey-light-dark: #e5e5e5;
$grey-light-darkest: #e0e0e0;

/*borders*/
$border-default: #e0e0e0;
$horizontal-line-separator: #e74010;

/*logo colours*/
$red: #AB0000;
$mid-blue: #3198cc;

/*Greyscale colours*/
$grey: #E5DDDD;
$grey-lightest: #FBF4F4;
$grey-medium: #9b9b9b;
$grey-dark: #807576;
$grey-darkest: #424242;

$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #eeeeee;

$blue-grey-50: #eceff1;
$blue-grey-100: #cfd8dc;
$blue-grey-200: #b0bec5;
$blue-grey-300: #90a4ae;
$blue-grey-400: #78909c;
$blue-grey-700: #455a64;

$primary-button-color: #006d77;
/*teal*/
$secondary-button-color: #261213;
/*pink*/
$cta-button-color: #ebb927;
/*dark yellow*/
$alert-button-color: #e74010;
/*red*/
$light-button-color: #999999;
/*grey medium*/
$dark-button-color: #000000;
/*black*/

$primary-badge-color: #007882;
/*blue*/
$secondary-badge-color: #9c27b0;
/*purple*/
$alert-badge-color: #e74010;
/*red*/
$success-badge-color: #4caf50;
/*green*/
$info-badge-color: #00bcd4;
/*cyan*/
$light-badge-color: #eeeeee;
/*grey light*/
$dark-badge-color: #666666;
/*grey dark*/

$primary-alert-color: #c7e5f9;
/*pale light blue*/
$secondary-alert-color: #f8e878;
/*pale yellow*/
$warning-alert-color: #f68787;
/*pale pink*/
$success-alert-color: #48d5db;
/*pale teal*/
$info-alert-color: #8bd0e9;
/*pale blue*/
$light-alert-color: #f4f5f5;
/*grey light*/
$dark-alert-color: #cccccc;
/*grey*/

/* ==========================================================================
  BUTTONS
   ========================================================================== */

$button-padding: 5px 20px;
$button-padding-small: 0 10px;
$button-border-rounded: 25px;
$button-font-size: 0.875rem;
$button-font-size-small: 0.625rem;
$button-text-style: uppercase;
$button-height: 40px;
$button-height-small: 35px;
$button-max-width: 160px;

/* ==========================================================================
  ANIMATION
   ========================================================================== */

$animate-default: all 0.5s ease;
$animate-card: all 0.3s ease-out;
$animate-background: background-color 0.5s ease;

/* ==========================================================================
  MISC
   ========================================================================== */

$box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
$box-shadow-button: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
$box-shadow-hover: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
$shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.2);
$shadow-hard: 10px 10px 0 0 rgba(235, 235, 235, 1);
$shadow-hard-small: 5px 5px 0 0 rgba(240, 240, 240, 1);
$light-opacity: rgba(255, 255, 255, 0.9);
$dark-opacity: rgba(0, 0, 0, 0.4);
$breadcrumb-divider: "\003e";

/* ==========================================================================
   Fonts
   ========================================================================== */

$primary-font: Poppins, sans-serif;

$x-small-space: -.015rem;
$small-space: 0;
$med-space: 0.075rem;
$space: 0.25rem;

$font-bold: 700;
$font-semi-bold: 600;
$font-regular: 400;
$font-light: 300;

/* ==========================================================================
   Newsletter bg
   ========================================================================== */
$newsletter-bg: #e74010;


/* ==========================================================================
   Badge
   ========================================================================== */

$badge-label: white;
$badge-background: $red;

/* ==========================================================================
   Card
   ========================================================================== */

$card-overlay-bg: #ef7744;
$featured-card-bg: #f7f7f7;

/* ==========================================================================
   List item bg
   ========================================================================== */
$list-item-bg: #eee0d1;

/* ==========================================================================
   Navbar Item Hover
   ========================================================================== */
$navbar-item-secondary: #e74010;


/* ==========================================================================
   Banner
   ========================================================================== */

$banner-wallpaper-width: 2750px;
$banner-wallpaper-height: 1440px;
$banner-waller-paper-before-width: 1370px; // todo: make a calculation
$banner-waller-paper-before-left: 690px;

$menu-font-size:1.125rem;
$body-font-size:1rem;
