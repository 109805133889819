body {
  font-family: $primary-font ;
}

.grid {
  grid-gap: 20px;

  @include mq($from: desktop) {
    grid-gap: 30px;
  }
}


h1,
h2,
h3 {
  font-family: $primary-font;
  font-weight: $font-semi-bold;
}

.datasummary-intro {
  font-family: $primary-font !important;
}


.row {
  &--main-image {
    background-color: $gray;
  }

  & .button--link:not(.menu--desktop-wrapper button) {
    color: $link-color !important;
  }

  &--black {
    background-color: $nearly-black;

    & .menu--desktop-wrapper {
      background-color: $nearly-black !important;

      & .menu--item--link a {
        color: $light-gray;

        &:hover {
          color: $secondary-color;
        }
      }

      li {
        padding-bottom: 0px;
      }
    }

  }

  &--top-centre-feature {
    .card--portrait .card__content-wrapper span:first-child {
      color: $link-color;
    }
  }

  & .code #mc_embed_signup_scroll .button {
    background-color: $primary-color !important;
    font-weight: $font-regular !important;
    text-transform: capitalize !important;
    font-size: 1rem !important;

    &:hover {
      background-color: darken($primary-color, 15%) !important;
    }
  }

  .button--link:not(.menu--desktop-wrapper button) {
    background: $linkButtonIcon no-repeat center left;
    background-color: transparent !important;

    &:hover {
      background: $linkButtonIcon no-repeat center left;
      border-color: $secondary-color !important;
    }
  }
}

.column {
  &--search-results span+span {
    color: $dark-gray !important;
    font-weight: $font-regular !important;
  }
}

p {
  & a {
    color: $primary-color;
  }


  em {
    font-size: 1rem !important;
    font-weight: 400 !important;
  }
}

.menu {
  &--desktop {
    & li {
      & a {

        &:hover {
          color: $secondary-color;
        }
      }
    }
  }

  &--item--link--icon {
    & svg {
      color: $secondary-color;
    }
  }

  &--desktop-logo {
    width: 460px;
    height: auto;
    padding-top: 14px;
  }

  &--mobile-logo {
    height: auto;
    padding-top: 20px;

    @include mq($from: mobile) {
      width: 50%;
      padding-top: 25px;
      left: 23%;
    }

    @include mq($from: tablet) {
      width: 35%;
      padding-top: 20px;
      left: 30%;
    }
  }

  &--item--link a {
    &:hover {
      color: $link-color;
    }
  }

}

.button--link:before,
a.button--link:before {
  background-color: $secondary-color !important;

  & svg {
    color: $link-color;
  }
}


.button,
a.button {
  &--cta {
    background-color: $primary-color !important;
    border-radius: 25px !important;

    &:hover {
      background-color: darken($primary-color, 15%) !important;
    }
  }
}

.backtotop {
  &:hover {
    background-color: darken($primary-color, 15%) !important;
  }
}

.card {
  & .badge {
    border-left: 1px solid $link-color;
    color: $link-color;
    font-size: 0.875rem;
    text-transform: capitalize;
    letter-spacing: $small-space;
  }

  a.badge {
    color: $link-color;
    font-size: .938rem;
  }

  &__title {
    a {
      &:hover {
        color: $secondary-color;
      }
    }
  }

  &__details {
    letter-spacing: $small-space;

    span {
      font-size: 1rem;
      display: block;

      &:first-child {
        font-weight: $font-regular !important;
      }
    }

    &--landscape {
      span:first-child {
        color: $link-color;
        font-weight: $font-regular !important;
      }
    }

    .date {
      color: $dark-gray !important;
      font-weight: $font-regular !important;
    }
  }

  &--leaders {

    .card__details--details,
    .card__details span:first-child {
      font-weight: $font-light !important;
      font-size: 1.125rem !important;
      color: $nearly-black !important;
    }

    .card__details--company {
      font-weight: $font-regular !important;
    }
  }
}

.card--company.card--sponsored .badge:before {
  background-color: $secondary-color;
  display: block;
}

.card--leaders.card--sponsored .badge:before {
  background-color: $secondary-color;
  display: block;
  width: 24px;
  height: 24px;
}

.header-right .menu--desktop li a {
  color: $dark-gray;

  &:hover {
    color: $secondary-color;
  }
}

.bg {
  &--light-grey {
    background-color: $light-gray !important;

    a.button--link {
      background-color: transparent !important;
    }

    .row--boxed {
      max-width: 1250px;
    }
  }

  &--grey {
    background-color: $gray;
  }
}


#mce-EMAIL {
  background-color: $light-gray !important;
}


.filter {
  &--search-refine {
    background-color: $dark-gray;

    .heading--section {
      color: $light-gray !important;
    }
  }

  .form-field--select select,
  .form-field--select select[disabled] {
    background-color: transparent;
    max-width: 200px;
  }
}


.list__item {
  .card__details--landscape span:first-child {
    color: $nearly-black !important;
    font-size: 1.125rem;
  }
}


.pagination_page-item {
  &:hover {
    background-color: $secondary-color;
  }

  &--current {
    border: 1px solid $secondary-color;
    color: $secondary-color;
  }
}

.pagination_page-previous,
.pagination_page-next {
  color: $primary-color;

  &:hover {
    background-color: $secondary-color;
  }
}

.pagination_load-more {
  background-color: $primary-color !important;

  &:hover {
    background-color: $secondary-color !important;
  }
}

.left-sidebar--company-profile .pic-featured .image__wrapper {
  background-image: $starIcon;
}

.newsletter-signup {

  p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .header-right .display-row .column {
    margin-top: 20%;
  }
}
