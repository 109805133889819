.button,
a.button {
  box-shadow: none;
  font-weight: $font-regular;
  letter-spacing: $small-space;
  min-width: max-content !important;
  background-color: $nearly-black !important;
  color: white;
  text-transform: none;


  &--center {
   display: flex;
   margin: 20px auto !important;
  }


  &:hover {
    text-decoration: none;
    background-color: darken($primary-color, 10%);
  }

  &--cta {
    background-color: $nearly-black !important;
    border-radius: 25px !important;

    &:hover {
      background-color: $primary-color !important;
    }
  }

  &--square {
    border-radius: 0;
  }

  &--secondary-color {
    background-color: $secondary-button-color;

    &:hover {
      background-color: darken($secondary-button-color, 10%);
    }
  }

  &--outline {
    background-color: transparent !important;
    border-width: 2px;
    border-style: solid;
    box-shadow: none;
    border-color: $secondary-button-color;
    color: $nearly-black;
    border-radius: $button-border-rounded;

    &:hover {
      background: $secondary-button-color !important;
      color: $white;
    }
  }

  &--plain {
    letter-spacing: $x-small-space;
  }

  &--dark {
    background-color: $dark-button-color;

    &:hover {
      background-color: darken($red, 10%);
    }
  }

  &--load-more {
    margin: 20px auto 0;
    width: 100%;
    max-width: 100%;
    height: 80px;
    color: $white;
    border-radius: 0;
    display: flex;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);

    @include mq($from: phablet) {
      margin: 40px auto 0;
      max-width: 50%;
    }

    @include mq($from: desktop) {
      height: 100px;
      width: 31%;
      max-width: 31%;
    }

    & a {
      color: $white;
    }

    &:hover {
      animation-name: hvr-pop;
      animation-duration: 0.3s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
    }

    &:focus {
      animation-name: hvr-pop;
      animation-duration: 0.3s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
    }
  }

  &--small {
    min-width: 50px;
    font-size: 0.75rem;
    font-weight: 400;
  }

  &--link:not(.menu--desktop-wrapper button) {
    width: max-content;
    padding: 0;
    margin-top: 30px;
    font-size: 1rem;
    min-width: 10px;
    border-radius: 0;
    transition: $animate-default;
    height: auto;
    background-color: white !important;
    color: $primary-color !important;
    border: 0;
    text-decoration: none;
    text-transform: none;
    @include hvr-underline-from-left($primary-color, 1px);
    color: $primary-color;
    line-height: 110%;
    letter-spacing: $small-space;
    padding-left: 25px;
    background: url("/static/img/arrow-right.svg") no-repeat center left;

    &:hover {
      background: none;
      color: $link-color;
    }

  }

  &--link-light {
    @include hvr-underline-from-left($white, 1px);
    color: $white;
    color: $white;
  }
}

.icon-button {
  &--play {
    background-color: rgba(250, 250, 250, 0.8);
    opacity: 1;
    border-radius: 50%;
    width: 37px;
    height: 37px;
    overflow: hidden;

    & i {
      color: $primary-color;
      font-size: 2.2rem;
    }

    &:hover {
      background-color: rgba(250, 250, 250, 0.2);
    }

    @include mq($from: desktop) {
      width: 49px;
      height: 49px;

      & i {
        font-size: 2.75rem;
      }
    }
  }

  &--menu {
    @include mq($from: desktop) {
      display: block;
    }
  }
}
