/*MIXINS use ''@include mixin' in css above all other styles in the class*/
/* Underline from centre */
@mixin hvr-underline-from-center($color, $height) {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  padding-bottom: 1px;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: $color;
    height: $height;
    transition-property: left, right;
    transition-duration: .3s;
    transition-timing-function: ease-out;
  }
  /*&:active:before {
    left: 0;
    right: 0;
  }

  &:focus:before {
    left: 0;
    right: 0;
  }*/
  &:hover:before {
    left: 0;
    right: 0;
  }
}
/* Underline from left */
@mixin hvr-underline-from-left($color, $height) {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  padding-bottom: 1px;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: $color;
    height: $height;
    transition-property: right;
    transition-duration: .3s;
    transition-timing-function: ease-out;
  }

  &:hover:before {
    right: 0;
  }
  /*&:focus:before {
    right: 0;
  }

  &:active:before {
    right: 0;
  }*/
}
/* Underline reveal */
@mixin hvr-underline-reveal($color, $height) {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    background: $color;
    height: $height;
    transform: translateY(4px);
    transition-property: transform;
    transition-duration: .3s;
    transition-timing-function: ease-out;
  }

  &:hover:before {
    transform: translateY(0);
  }
  /*&:focus:before {
    transform: translateY(0);
  }

  &:active:before {
    transform: translateY(0);
  }*/
}
/* Ripple Out */
@keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}
@mixin hvr-ripple-out($border-color, $border-width) {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    border-color: $border-color;
    border-style: solid;
    border-width: $border-width;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation-duration: 1s;
  }

  &:hover:before {
    animation-name: hvr-ripple-out;
  }
}
/* Ripple In */
@keyframes hvr-ripple-in {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}
@mixin hvr-ripple-in($border-color, $border-width) {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    border-color: $border-color;
    border-style: solid;
    border-width: $border-width;
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
    animation-duration: 1s;
  }

  &:hover:before {
    animation-name: hvr-ripple-in;
  }
}
/* Shutter Out Horizontal */
@mixin hvr-shutter-out-horizontal($background, $font-color) {
  display: flex;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: $background;
  transition-property: color;
  transition-duration: .3s;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: darken($background, 10%);
    transform: scaleX(0);
    transform-origin: 50%;
    transition-property: transform;
    transition-duration: .3s;
    transition-timing-function: ease-out;
  }

  &:hover {
    color: $font-color;

    &:before {
      transform: scaleX(1);
    }
  }

  &:active {
    color: $font-color;

    &:before {
      transform: scaleX(1);
    }
  }

  &:focus {
    color: $font-color;

    &:before {
      transform: scaleX(1);
    }
  }
}
/* Shutter In Horizontal */
@mixin hvr-shutter-in-horizontal($background, $font-color) {
  display: flex;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: darken($background, 10%);
  transition-property: color;
  transition-duration: .3s;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $background;
    transform: scaleX(1);
    transform-origin: 50%;
    transition-property: transform;
    transition-duration: .3s;
    transition-timing-function: ease-out;
  }

  &:hover {
    color: $white;

    &:before {
      transform: scaleX(0);
    }
  }

  &:focus {
    color: $white;

    &:before {
      transform: scaleX(0);
    }
  }

  &:active {
    color: $white;

    &:before {
      transform: scaleX(0);
    }
  }
}
/* Rectangle Out */
@mixin hvr-rectangle-out($background) {
  display: flex;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: $background;
  transition-property: color;
  transition-duration: .3s;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: darken($background, 10%);
    transform: scale(0);
    transition-property: transform;
    transition-duration: .3s;
    transition-timing-function: ease-out;


  }
  &:hover {
    color: $white;

    &:before {
      transform: scale(1);
    }
  }

  &:active {
    color: $white;

    &:before {
      transform: scale(1);
    }
  }

  &:focus {
    color: $white;

    &:before {
      transform: scale(1);
    }
  }
}
/*basic link with coloured underline + hover*/
@mixin link-basic {
  color: $link-color;
  padding-bottom: 3px;
  border-bottom: 1px solid $link-color;
  transition: $animate-default;

  &:hover {
    border-color: transparent;
  }
}
