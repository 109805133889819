.alert {
  &--cookies {
    background-color: rgba($yellow-dark, .8);
    border: 0;
    border-radius: 0;
    width: 100%;

    & a {
      color: $black;
      text-decoration: underline;
      border: 0;

      &:hover {
        text-decoration: none;
      }
    }

    & .icon-button {
      opacity: 1;

      & i {
        font-size: 1.25rem;
      }
    }

    & p {
      font-size: 1rem;
    }

    &.fixed {
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 10;
      margin: 0;
    }
  }

  &--dismissable {
    padding-right: 20px;
    @include mq($from: desktop) {
      padding-right: 110px;
    }

    & button {
      position: relative;
      top: auto;
      right: auto;
      margin: 10px auto 0;
      @include mq($from: desktop) {
        position: absolute;
        top: 5px;
        right: 10px;
      }
    }
  }
}
