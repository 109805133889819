label {
  white-space: nowrap;
  font-family: $primary-font;
  margin-right: .5rem;
}

input {
  height: 50px;
  border-color: $blue-grey-200;
  padding: 5px 15px;
  font-family: $primary-font;
  border-radius: 4px;
  background-color: $grey-lightest;

  &:hover {
    border-color: $link-color;
  }
}

select {
  font-family: $primary-font;
  border-bottom: none;
}

textarea {
  border-color: $blue-grey-200;
}

.form-field--password .show-hide-password {
  bottom: 17px;
}

.form-radio {
  background-color: $white;
  color: $primary-color;
  border: 1px solid $primary-color;
}

.form-radio:checked::before {
  background-color: $primary-color;
}

.form-radio:hover {
  background-color: lighten($teal-lightest, 35%);
}

.form-radio:checked {
  background-color: lighten($teal-lightest, 25%);
  border-color: lighten($teal-lightest, 25%);
}

.form-checkbox {
  background-color: $white;
  color: $primary-color;
  border: 1px solid $primary-color;
}

.form-checkbox:checked::before {
  left: 5px;
  top: 1px;
}

.form-checkbox:hover {
  background-color: lighten($teal-lightest, 35%);
}

.form-checkbox:checked {
  background-color: lighten($teal-lightest, 25%);
  border-color: lighten($teal-lightest, 25%);
}

.form-message {
  margin: 3px 0 0;
  font-size: .875rem;
}

.form {
  width: calc(100% - 10px);
  border: 1px solid $grey-light;
  box-shadow: $shadow-hard;
  padding: 20px;

  @include mq($from: tablet) {
    padding: 30px;
  }

  @include mq($from: desktop) {
    padding: 50px;
  }

  &__heading {
    font-size: 1.125rem;
    font-weight: $font-semi-bold;
    margin: 0 0 20px;
    letter-spacing: $x-small-space;
  }
}

.form-group {
  display: inline-block;
  margin: 10px 0;

  &__heading {
    font-size: 1.125rem;
    font-weight: $font-semi-bold;
    margin: 0 0 20px;
    letter-spacing: $x-small-space;
  }
}

.sort-by{
  display: inline-block;
  margin-right: 1rem;
  width: 360px;
  @include mq($from: desktop) {
  width: 400px;
  }

  .form-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 10px;
    width: 100%;
  }
}


.show-hide-password {
  i {
    display: none;
  }
}
