.column {


  & + .column {
    @include mq($from: mobile) {
      margin-top: 30px;
      margin-left: 0px;
    }

    @include mq($from: tablet) {
      margin-top: 0;
    }
  }

  &--content {
    flex: 1 1 auto;
    @include mq($from: tablet) {
      margin-right: 20px;
    }
    @include mq($from: desktop) {
      margin-right: 40px;
    }
    & p {
      line-height: 1.6;
      margin: 1rem 0;
    }
  }

  &--sidebar {
    max-width: 300px;
    flex: 1 1 auto;
    margin-bottom: auto;
    @include mq($from: tablet) {
      flex: 0 0 300px;
    }
    @include mq($from: desktop) {
      flex: 0 0 360px;
      max-width: 360px;
    }
  }

  &--sticky,
  &.sticky {
    @include mq($from: tablet) {
      position: sticky;
      top: 124px;
    }
  }


  &--justify-content {
    @apply space-y-3 items-start mx-auto ml-6 p-4;

    & p:first-of-type {
      @apply text-2xl;
      @apply text-button;
    }
  }

  &--search-results {
    .list {
      margin-top: 0;
    }

    h2 {
      margin-top:0;
    }

    .card__content {
      margin-top: 2px !important;
  }

  span + span {
    width: 100%;
    font-size: 1rem !important;
    color: $dark-gray !important;
  }
  }

  .pagination {
    margin-bottom: 3rem;
    margin-top: 0;
    .pagination_load-more {
      padding: 5px 20px;
      color: $white;
      background-color: $link-color;
      border-radius: 25px;
      margin: 0 auto;
      &:hover {
        background-color: $red;
      }
    }
  }

  .card--leaders.card--featured .badge {
    border-left: none !important;
    padding-left: 0 !important;
    position: absolute;
    top: 5px;
    right: -20px;
  }

  &--two-thirds {
    .grid--one {
      .card--portrait {
        .card__title {
          font-size: 1.9rem !important;
          @include mq($from: tablet) {
          font-size: 2.05rem !important;
          letter-spacing: $small-space;
          margin-top: -5px;
          }
        }
      }
    }

  }

  &--sponsor {
    padding-top: 30px;
    .stats {
      margin-top: 10px;
      &__value {
        font-size: 1.125rem;
        font-weight: $font-semi-bold;
      }
      a {
        color: $black;
      }
      &__text {
        border: 0;
        justify-content: center;
        @include mq($from: desktop) {
          align-items: flex-end;
        }
      }
    }
    .image {
      margin: 0 auto;
    }
    img {
      border: 2px solid $gray;
    }
  }

  &--one-third {
    .card__details span {
      margin-bottom: -3px!important;
    }
  }

}


@media only screen and (max-width: 768px){
.column--sidebar {
  max-width: 98%;
}
.column--sidebar .image {
  width: 100%;
}
}

.left-sidebar{
  display: block;
  text-align: right;
  margin-top: 1.5rem;

  .image-author-profile{
   text-align: right;
   border-radius: 25px;

    img {
      height:80px;
      width: 80px;
      border-radius: 50%;
      object-fit: cover;
      object-position: top;
      margin-bottom: 20px;

      @include mq($from: desktop) {
        width: 160px;
        height: 160px;
        float: right;
      }
    }
  }

  .stats{
    border-top: 1px solid #ccc;
    padding-top: 20px;
    }

  .stats__heading {
    text-align: right;

    & a {
    text-transform: capitalize !important;
    font-size: 1.5rem;
    font-weight: 600 !important;
    color: $nearly-black;
    }

    & span{
    margin-right: 0;
      letter-spacing: 0;
    }
  }

    .name:after{
      content: "\a";
      white-space: pre;
    }

    .title a, .company a{
      font-weight: 300 !important;
    }

    .profile-summary a{
      text-transform:lowercase !important;
      color: $nearly-black !important;
      font-size: 1rem !important;
      letter-spacing: 0 !important;
      cursor: not-allowed;
      text-decoration: none !important;
      font-weight: 300 !important;
      line-height: 160%;
      margin-top: 10px;
    }
    .profile-summary::before{
      content: "\a";
      white-space: pre;
    }



    .sponsored-company {
      .image__wrapper{
      background-image: url(/static/img/star-red.svg);
      background-repeat: no-repeat;
      background-position: 95% 4%;
      }
    }

  &--company-profile{
    text-align: left;
    flex-direction: row;
    @include mq($from: tablet) {
      flex-direction: column;
    }
    .image{
      max-width: 48.5%;

      @include mq($from: tablet) {
        max-width: 100%;
      }

      &__wrapper{
        padding: .5rem;
         @include mq($from: tablet) {
          padding: 2.5rem;
         }
      }
    }

    .pic-featured {
      .image__wrapper{
      background-image: url(/static/img/star-red.svg);
      background-repeat: no-repeat;
      background-position: 95% 4%;
      }
    }

    .heading--section {
      font-size: 1.35rem !important;
      background-image:none;
    }
    .heading--section+.button--left {
      margin-top: 0 !important;
    }

    .button--left {
      margin: .8rem 0 !important;
    }

    .social--follow {
      margin-top: 2rem;
    }

    .image{
      border: 2px solid $gray;
      margin-bottom: 2rem;
      width: 48.5%;
      margin-right: 10px;
      @include mq($from: tablet) {
        width: 100%;
      }
      &:last-of-type {
        display: flex;
        justify-content: center;
        align-content: center;
        .image__wrapper{
          display: flex;
          justify-content: center;
        }
      }
    }

    p {
      overflow-wrap: break-word;
      line-height: 1.8rem;
      margin: .4rem 0;
    }

    .manager {
      padding: 10px 0;
      border-top: 2px solid $gray;
      border-bottom: 1px solid $gray;
      margin: 2rem 0;
    }

    .linkedin-link {
      background: url("/static/img/in-icon.svg") no-repeat center left;
      padding-left: 40px;
    }

    .twitter-link {
      background: url("/static/img/tw-icon.svg") no-repeat center left;
      padding-left: 40px;
    }

    .facebook-link {
      background: url("/static/img/fb-icon.svg") no-repeat center left;
      padding-left: 40px;
    }

  }
}

.left-sidebar--company-profile~.column--half {
  .row:first-child  {
    margin: 0 !important;
  }
  .spec-block {
    margin: 0;
  }
  h2.heading--section {
    margin-top: 0;
   }
}

.right-sidebar{
  margin-top: 30px !important;
  @include mq($from: desktop) {
    margin-top: 0 !important;
  }

  .grid {
    //margin-top: -20px;
    grid-gap: 0;
  }

  .card .badge {
  position: relative;
  }

  .heading--section h2 {
  margin-top: 0;
  }

  .card--portrait {
    border-top: 1px solid $grey;
    padding-top: 0;
    margin-top: -5px;

    &:last-of-type {
      margin-bottom: 25px;
    }
  }
  /*position: sticky;
  top: 200px;*/
  display: block;
  height: fit-content;

  .row--heading-section {
    margin-top: 0 !important;
  }

}

.list_total_results{

  font-size: 1.35rem;
  font-weight: $font-semi-bold;
  padding: 0;
  margin-top: 0 !important;
  @include mq($from: tablet) {
    padding: 0 0 2.15rem 0;
  }

}

